import React from 'react';
import { Spinner, Container } from 'react-bootstrap';

export default function Loading() {
    return (
        <Container fluid className="text-center mt-5 mb-5">
            <Spinner animation="border" variant="primary" />
            <p className=" text-muted mt-2">Carregando</p>
        </Container>
    );
}