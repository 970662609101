import React, { useState } from 'react';

import { Modal, Button} from 'react-bootstrap';
import Icone from '../../components/Icone';
import FormProgramacaoRetira from '../../components/programacao/formProgramacaoRetira';


export default function EdicaoRetira(props) {
    const [show, setShow] = useState(false);
    const [erro, setErro] = useState('')

    const handleShow = () => {
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        props.atualizaVisao();
        erro && setErro('');
    }

    return (
        <>
            <Button id='btnEditarProgramacaoRetira' variant='light' onClick={() => handleShow()} disabled={!props.item}>
                <Icone icon='eye' customClass='mr-2 text-primary' />
                Visualizar
            </Button>


            <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Icone icon='calendar-check' customClass='mr-2 text-primary' />
                        {'Visualizar Programação de Retira N° ' + props.item.numeroauxiliar}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormProgramacaoRetira item={props.item} show={show} status='Edicao' handleClose={() => handleClose()} />
                </Modal.Body>
            </Modal>
        </>
    );
}