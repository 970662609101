import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { formataData } from '../../../../../../util/formatacao'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

export default function CardVencimento(props) {
    return (
        <Alert variant="primary">
            <Alert.Heading>
                <FontAwesomeIcon icon="clock" className="mr-2" />
                Vencimento: {formataData(props.dataVencimento)}
            </Alert.Heading>
            <strong>ID do Lançamento: </strong>{props.idLan} <br />
            <strong>Data Prev. Baixa: </strong>{formataData(props.dataPrevBaixa)} <br />
            <strong>Valor Original: </strong> R$ {props.valorOriginal} <br />
            <strong>Código da Coligada: </strong>{props.codColigada}<br />
            <strong>ID do Movimento: </strong>{props.idMov} <br />
            <strong>Número do Documento: </strong>{props.numDoc} <br />
           
        </Alert>
    );
}