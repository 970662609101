import React from 'react';
import { Link } from 'react-router-dom'

import { ButtonGroup, Button } from 'react-bootstrap'
import ModalReavaliaLanc from '../../../../components/modalReavaliaLanc';
import ModalAprovMultipla from '../../../../components/ModalAprovMultipla';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

export default function BtnVisaoLanc(props) {
    const disable = (props.status === "Reprovado" || props.status === "Aprovado");

    return (
        <ButtonGroup>
            <Link to={`/detalheLancamento/${props.idlan}`}
                className="btn btn-primary btn-grid mr-2" data-placement="right" title="Detalhes do Lançamento">
                <FontAwesomeIcon icon="list-alt" />
            </Link> {" "}

            {disable === false ?
                <>
                    <ModalReavaliaLanc
                        idlan={props.idlan}
                        visao={true} />

                    <ModalAprovMultipla
                        visao={true}
                        idlanAtual={props.idlan} />

                </>
                :
                <>
                    <Button variant="warning" disabled className="mr-2">
                        <FontAwesomeIcon icon="thumbs-down" />
                    </Button>

                    <Button variant="success" disabled className="mr-2">
                        <FontAwesomeIcon icon="check" />
                    </Button>
                </>
            }
        </ButtonGroup>
    );
}