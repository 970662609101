import React from 'react';
import { Form, Col } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formataData, formataDataHora, formataDinheiro } from '../../../../util/formatacao';

export default function FormDetalheLanc(props) {
    function trataDados(dados) {
        if (dados) {
            const result = dados.map((item) => {
                return "Operação: " + item.tipooperacao +
                "\n" +
                // "Id Lançamento: " + item.idlan +
                // "\n" +
                // "Data de envio: " + formataData(item.dataenvio) +
                // "\n" +
                "Data de Operação: " + formataData(item.dataoperacao)
                + "\n\n";
              });
              return result;
        }
    }
    
    return (
        <Form>
            {console.log(props)}
            <Form.Row>
                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="IdMov">Id Lançamento</Form.Label>
                    <Form.Control readOnly id="IdMov" value={props.idlan} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="numDoc">Num. Documento</Form.Label>
                    <Form.Control readOnly id="numDoc" value={props.numerodocumento} />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label htmlFor="fornecedor">Fornecedor</Form.Label>
                    <Form.Control readOnly id="fornecedor" value={props.codcfo + ' - ' + props.fornecedor} />
                </Form.Group>

            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md={6}>
                    <Form.Label htmlFor="inputTipoMovimento">Tipo de Documento</Form.Label>
                    <Form.Control readOnly id="inputTipoMovimento" value={props.tipoDocto} />
                </Form.Group>


                <Form.Group as={Col}>
                    <Form.Label htmlFor="inputCondPag">Plano Financeiro</Form.Label>
                    <Form.Control readOnly id="inputCondPag" value={props.planoFinacneiro} />
                </Form.Group>
            </Form.Row>
            <Form.Row>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputVlrBruto">Data de Emissão</Form.Label>
                    <Form.Control readOnly id="inputQuantItens"
                        value={formataData(props.dataemissao)} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputDataVenc">Data Vencimento</Form.Label>
                    <Form.Control readOnly id="inputDataVenc" value={formataData(props.datavencimento)} />

                </Form.Group>
                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputDataPrevBaixa">Data de Prev. de Baixa</Form.Label>
                    <Form.Control readOnly id="inputDataPrevBaixa" value={formataData(props.dataprevbaixa)} />
                </Form.Group>


                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputDataAprovacao">Data Envio Aprovação</Form.Label>
                    <InputGroup>
                        <Form.Control readOnly id="inputDataAprovacao" value={formataData(props.dataenvio ?? 0)} />
                        <InputGroup.Text id="basic-addon2" title={
                            "Detalhes da aprovação: \n" +
                            trataDados(props.detalheLan)
                        }>
                            <FontAwesomeIcon icon="info" />
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>
               
                <Form.Group as={Col}>
                    <Form.Label htmlFor="Aprovador">Aprovador Financeiro</Form.Label>
                    <Form.Control readOnly id="Aprovador" value={(props.nomeaprovador ?? '') + ' - ' + formataDataHora(props.dataaprovador)} />
                </Form.Group>
            </Form.Row>

            {props.idmov === 0 ?
                <Form.Row>
                    <Form.Group as={Col} md={3}>
                        <Form.Label htmlFor="aprovadorcompra">Aprovador de Compra</Form.Label>
                        <Form.Control readOnly id="aprovadorcompra" value={props.aprovadorcompra} />
                    </Form.Group>
                </Form.Row>
                :
                <></>
            }

            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label htmlFor="historico">Histórico</Form.Label>
                    <Form.Control as="textarea" rows="5" readOnly id="historico" value={props.historico} />
                </Form.Group>
            </Form.Row>
        </Form>
    );
}