import React from 'react';
import moment from 'moment';
import { Container } from 'react-bootstrap';
import './style.css';

export default function Rodape() {
    return (
        <Container fluid className="text-center footer">
            <hr />
            <p className="text-muted">Copyright &copy; {moment().format('YYYY')} - RC Consultoria em
         Soluções Inteligentes. Todos os direitos reservados.</p>
        </Container>
    );
}
