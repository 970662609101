import React from 'react';
import './style.css';

export default function StatusBx(props) {

    if (props !== undefined) {
        let type;
        let message;

        switch (props.status) {
            case 0:
                type = "badge badge-primary";
                message = "Em Aberto";
                break;

            case 1:
                type = "badge badge-success";
                message = "Baixado";
                break;

            case 2:
                type = "badge badge-danger";
                message = "Cancelado";
                break;

            case 3:
                type = "badge badge-dark"
                message = "Baixado por Acordo";
                break;

            case 4:
                type = "badge badge-warning";
                message = "Baixado Parcialmente";
                break;

            case 5:
                type = "badge badge-dark";
                message = "Borderô";
                break;

            default:
                type = "badge badge-dark";
                break;
        }

        return (
            <span className={type} data-toggle="tooltip" data-placement="right" title={message}>
                {props.status}
            </span >
        );
    }
}
