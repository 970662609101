import React, { useState } from 'react';
import api from '../../services/apiMineracao';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Button } from 'react-bootstrap';
import AlertErro from '../../components/alertErro';
import ButtonContent from '../../components/buttonContent';
import TituloModal from '../../components/tituloModal'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

export default function ModalExclusão(props) {
    const [show, setShow] = useState(false);
    const [excluindo, setExcluindo] = useState(false);
    const [erro, setErro] = useState('');

    const handleShow = () => setShow(true);
    const handleClose = () => {
        erro && setErro('');
        setShow(false);
    };


    function excluir() {
        setExcluindo(true);
        erro && setErro('');

        const config = {
            headers: {
                "IDPROGRAMACAO": props.idprogramacao
            }
        }

        api.delete('/ExcluirProgramacao', config)
            .then(resp => {
                if (resp.data.CODIGO === 0)
                {
                    setErro(resp.data.DESCRICAO);
                }
                else
                {
                    handleClose();
                }
            })
            .catch(err => setErro(err.toString()))
            .finally(() => {
                setExcluindo(false);
                props.atualizaVisao();
            });
    }


    const headerDialog = () => <TituloModal variant='danger' icone='calendar-times' titulo={'Excluir programação N° ' + props.idprogramacao} />

    const contentDialog = () => (
        <>
            <p className='mt-2 mb-4'>Deseja realmente excluir a programação n° {props.idprogramacao}?</p>

            {erro && <AlertErro mensagem={erro} />}

            <div className='text-right'>
                <Button variant='light' onClick={() => handleClose()} className='mr-2' disabled={excluindo}>
                    Cancelar
                </Button>

                <Button variant='danger' onClick={() => excluir()} disabled={excluindo}>
                    <ButtonContent texto='Excluir Programação' textoCarregando='Excluindo...' carregando={excluindo} />
                </Button>
            </div>
        </>
    )


    return (
        <>
            <Button variant="light" onClick={() => handleShow()} disabled={!(props.status)}>
                <FontAwesomeIcon className="mr-1 text-danger" icon="times" />
                Excluir
            </Button>


            <DialogComponent target='#modalVisao'
                header={headerDialog}
                content={contentDialog}
                visible={show}
                close={handleClose}
                width='35%'
                zIndex='999999'
                isModal={true}
            />
        </>
    );

}
