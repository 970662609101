import React from 'react';

import { Alert } from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';

export default function DataGrid(props) {
    return (
        <>
            <BootstrapTable
                keyField={props.identificador}
                data={props.dados}
                columns={props.colunas}
                loading={props.carregando}
                noDataIndication={<Alert variant="secondary" className="text-center">Nenhum registro encontrado!</Alert>}
                hover={true}
                bootstrap4={true}
                bordered={false}
                overlay={overlayFactory({ spinner: true })}
                wrapperClasses="table-responsive"
                pagination={props.paginacao === true ? paginationFactory() : null }
            />
        </>
    );
}