import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { formataData, formataDinheiro } from '../../../../util/formatacao';

import ModalAprovMultipla from '../../../../components/ModalAprovMultipla';
import ModalReavaliaLanc from '../../../../components/modalReavaliaLanc';
import useKeyPress from '../../../../util/useKeyPress';

import { Alert, Button, Badge } from 'react-bootstrap';
import StatusBx from '../statusBx';
import Icone from '../../../../components/Icone';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


export default function LancVinculados(props) {
    const [node, setNode] = useState('');
    const history = useHistory();

    useEffect(() => {

    }, [props]);

    const selected = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: [props.idlanAtual],
        nonSelectable: getFechados()
    }

    const colunas = [
        {
            dataField: 'idlan',
            text: 'Ref.',
            headerAlign: 'left',
            align: 'left',
            sort: true,

        },
        {
            dataField: 'numerodocumento',
            text: 'Num. Documento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            headerAlign: 'center',
            align: 'center',
            sort: true,
            formatter: (value) => (
                <Badge variant={value === 'A' ? 'primary' : 'success'} > { value}</ Badge>
            )
        },
        {
            dataField: 'statuslan',
            text: 'Status Bx',
            headerAlign: 'center',
            align: 'center',
            sort: true,
            formatter: (value) => (
                <StatusBx status={value} />
            )
        },
        {
            dataField: 'dataemissao',
            text: 'Emissão',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
        },
        {
            dataField: 'dataprevbaixa',
            text: 'Prev. Baixa',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
        },
        {
            dataField: 'datavencimento',
            text: 'Vencimento',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
        },
        {
            dataField: 'valor',
            text: 'Valor',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value ?? 0),
            sort: true,
        },
        {
            dataField: 'empresaFc',
            text: 'Fluxo de Caixa',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'planoFinacneiro',
            text: 'Plano Financeiro',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'tipoDocto',
            text: 'Tipo Documento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
    ]

    const key1 = useKeyPress("v")
    const key2 = useKeyPress("V")
    const key3 = useKeyPress("Alt")
    useEffect(() => {
        if ((key1 || key2) && key3) {
            history.goBack();
        }
        // eslint-disable-next-line
    }, [key1, key2, key3])


    //pega os lançamentos que não podem ser aprovados
    //novamente e retorna em um array para travar a grid
    function getFechados() {
        let valores = [];
        if (props.dados) {
            valores = props.dados.map((item) => {
                if (item.status !== 'A' || item.statuslan !== 0) {
                    return item.idlan;
                }
            })
        }
        return valores;
        // eslint-disable-next-line
    }


    return (
        <>
            <h5 className="mt-4 mb-3" >Lançamentos Vinculados</h5>
            {props.dados ?
                <>
                    <BootstrapTable
                        keyField="idlan"
                        data={props.dados}
                        columns={colunas}
                        noDataIndication={<Alert variant="secondary" className="text-center">Nenhum registro encontrado!</Alert>}
                        hover={true}
                        bootstrap4={true}
                        ref={n => setNode(n)}
                        selectRow={selected}
                        pagination={paginationFactory()}
                        bordered={false}
                        wrapperClasses="table-responsive"
                    />


                    <div class="row mb-2">
                        <div class="col-4">
                            <div className="container">
                                <h6>Status</h6>

                                <Badge variant="success" className="mr-1">F</Badge>
                                <small>Já Aprovado</small>

                                <Badge variant="primary" className="ml-3 mr-1">A</Badge>
                                <small>Aberto</small>
                            </div>
                        </div>

                        <div class="col">
                            <h6>Status Bx</h6>

                            <Badge variant="primary" className="mr-1">0</Badge>
                            <small>Em aberto</small>

                            <Badge variant="success" className="ml-3 mr-1">1</Badge>
                            <small>Baixado</small>

                            <Badge variant="danger" className="ml-3 mr-1">2</Badge>
                            <small>Cancelado</small>

                            <Badge variant="warning" className="ml-3 mr-1">3</Badge>
                            <small>Baixado com Acordo</small>

                            <Badge variant="dark" className="ml-3 mr-1">4</Badge>
                            <small>Baixado Parcialmente</small>
                        </div>
                    </div>
                    <hr />

                    <div className="text-right">
                        <Button variant={'primary'} className='mr-2' onClick={() => history.goBack()}>
                            <Icone icon="arrow-left" customClass="mr-1" />
                                Voltar
                        </Button>

                        {props.status !== 'F' && !props.carregando ?
                            <>
                                <ModalReavaliaLanc
                                    idlan={props.idlanAtual}
                                    visao={false} />

                                <ModalAprovMultipla
                                    visao={false}
                                    lancamentos={node}
                                    idlanAtual={props.idlanAtual} />
                            </>
                            :
                            <>
                                <Button variant="warning" disabled className="mr-2">
                                    <Icone icon="thumbs-down" />
                                    &nbsp; Reavaliar
                                </Button>

                                <Button variant="success" disabled className="mr-2">
                                    <Icone icon="check" />
                                    &nbsp; Aprovar
                                </Button>
                            </>
                        }
                    </div>
                </>
                : <></>
            }
        </>
    );
}