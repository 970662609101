import moment from 'moment';
import cryptojs from 'crypto-js';

/* ======= Formaters ========*/

//Formata dinheiro para BRL (Ex: R$ 100,23)
export function FormatMoney(value = 0) {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
}

//Formata o numero pra decimal de acordo 
export function FormatNumber(value = 0, fractionalDigits = 2) {
    return value.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: fractionalDigits });
}


//Formata a data e hora informadas
export function FormatDateTime(value) {
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
}


//Formata a data informada
export function FormatDate(value) {
    return moment(value).format('DD/MM/YYYY');
}


/* ======= misc  ======== */

//Retorna a data e hora atual formatada
export function DateTimeNowFormated() {
    return moment().format("DD/MM/YYYY HH:mm:ss");
}


export function FormatDate2(value){
   return moment(value).format('YYYY-MM-DD');
}

//Redireciona para o caminho especificado
export function RedirectTo(path, history) {
    history.push(path);
}


//Criptograva um texto em MD5 e o retorna
export function ToMD5String(value) {
    let encryptedValue = '';

    if (value !== '') {
        encryptedValue = cryptojs.MD5(value)
            .toString()
            .toUpperCase();
    }

    return encryptedValue;
}


//gera um arquivo .pdf para download
export function GeneratePDFFile(fileData) {
    let base64str = fileData;

    let binary = atob(base64str.replace(/\s/g, ''));
    let len = binary.length;
    let buffer = new ArrayBuffer(len);
    let view = new Uint8Array(buffer);

    for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    let blob = new Blob([view], { type: 'application/pdf' });

    let fileURL = URL.createObjectURL(blob);

    let a = document.createElement('a');
    a.href = fileURL;
    a.target = "_blank";
    a.download = `medicao-${this.props.idmov}.pdf`;
    document.body.appendChild(a);
    a.click();
}