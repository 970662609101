import React, { Component } from 'react';
import Navbar from '../../components/navbar';
import { Link } from 'react-router-dom';
import { RedirectTo } from '../../util/utilLibrary';
import api from '../../services/api';

import { Form, FormGroup, Button, Spinner } from 'react-bootstrap';
import { formataDinheiro } from '../../util/formatacao';
import CardDetalhes from '../../components/cardDetalhes';
import Rodape from '../../components/rodape';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);


class AprovaMovimento extends Component {
    constructor(props) {
        super(props);

        this.state = {
            idMov: '',
            numMov: '',
            nomeFantasia: '',
            valorLiquido: '',
            codtmv: '',
            usuarioMestre: '',
            dependencia: '',
            idAprovacao: '',
            sequencial: '',
            codusuario: '',
            validando: '',
            btnAprovaDisabled: '',
            nomevendedor: '',
            erro: '',
            warning: '',
        }
    }


    componentDidMount() {
        this.setState({
            idMov: this.props.match.params.idmov,
            numMov: this.props.match.params.numeromov,
            nomeFantasia: this.props.match.params.nomefantasia,
            valorLiquido: parseFloat(this.props.match.params.valor),
            codtmv: this.props.match.params.codtmv,
            usuarioMestre: this.props.match.params.usuariomestre,
            dependencia: this.props.match.params.dependencia,
            idAprovacao: this.props.match.params.idaprovacao,
            sequencial: this.props.match.params.sequencial,
            codusuario: this.props.match.params.codusuario,
            nomevendedor: this.props.match.params.nomevendedor,
            validando: false,
            btnAprovaDisabled: false,
        });
    }



    handleAprovaMov = async e => {
        e.preventDefault();

        this.setState({ validando: true, btnAprovaDisabled: true });

        let config = {
            headers: {
                'IDMOV': this.state.idMov,
                'CODTMV': this.state.codtmv,
                'CODUSUARIOAPROVACAO': sessionStorage.getItem('RC_USR'),
                'CODUSUARIOMESTRE': this.state.usuarioMestre,
                'DEPENDENCIA': this.state.dependencia,
                'IDAPROVACAO': this.state.idAprovacao,
                'SEQUENCIAL': this.state.sequencial,
                'CODUSUARIO': this.state.codusuario,
                'NOME_VENDEDOR': this.state.nomevendedor
            }
        }

        try {

            //faz a requisição
            const response = await api.get('/AprovacaoMovimentoObras', config);
            this.setState({ ret: response.data });

            //verifica se teve sucesso
            if (this.state.ret.CODIGO === '1') {
                RedirectTo('/movimentos', this.props.history);
            }
            else if (this.state.ret.CODIGO === '2') {
                this.setState({ validando: false, btnAprovaDisabled: true, warning: this.state.ret.DESCRICAO });
            }
            else {
                this.setState({ validando: false, btnAprovaDisabled: false, erro: this.state.ret.DESCRICAO });
            }
        }
        catch (err) {
            this.setState({ validando: false, btnAprovaDisabled: false, erro: 'Erro ao efetuar aprovação, tente novamente mais tarde.' });
            console.log(err);
        }
    }


    render() {
        return (
            <div>
                <Navbar />

                <CardDetalhes titulo="Aprovação do Movimento" subtitulo="Confira todos os dados abaixo!" btnImprimeVisible='false'>

                    {this.state.erro && <div className="alert alert-danger" role="alert">Erro:
                        <FontAwesomeIcon className="mr-2" icon="times" />
                        {this.state.erro}
                    </div>}

                    {this.state.warning && <div className="alert alert-warning" role="alert">
                        <FontAwesomeIcon className="mr-2" icon="exclamation-triangle" />
                        {this.state.warning}
                    </div>}


                    <Form onSubmit={this.handleAprovaMov}>
                        <Form.Row>
                            <FormGroup className="col-md-2">
                                <Form.Label htmlFor="inputNumMov">Num. Movimento</Form.Label>
                                <Form.Control readOnly id="inputNumMov" value={this.state.numMov} />
                            </FormGroup>

                            <FormGroup className="col-md-6">
                                <Form.Label htmlFor="inputNomeFantasia">Nome Fantasia</Form.Label>
                                <Form.Control readOnly id="inputNomeFantasia" value={this.state.nomeFantasia} />
                            </FormGroup>

                            <FormGroup className="col">
                                <Form.Label htmlFor="inputVlrLiquido">Valor Líquido</Form.Label>
                                <Form.Control readOnly id="inputVlrLiquido" value={formataDinheiro(this.state.valorLiquido ?? 0)} />
                            </FormGroup>
                        </Form.Row>
                        <hr />
                        <div className="text-right">
                            <Link to='/movimentos' className="btn btn-light mr-2">
                                Cancelar
                            </Link>
                            <Button type="submit" variant="success" disabled={this.state.btnAprovaDisabled}>
                                {this.state.validando ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            className="mr-2"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Aprovando...
                                    </>

                                    :
                                    <>
                                        <FontAwesomeIcon icon="check" className="mr-2" />
                                        Aprovar Movimento
                                    </>
                                }
                            </Button>
                        </div>
                    </Form>
                </CardDetalhes>

                <Rodape />

            </div>
        );
    }
}

export default AprovaMovimento;