import React from 'react';
import { Container, Card, Col, Row } from 'react-bootstrap';
import CardTitulo from '../cardTitulo';

export default function CardVisao(props) {
    return (
        <Container fluid className="container-card">
            <Card className="card-visao">
                <Card.Body>
                    <Row className="mt-3 mb-1">

                        <CardTitulo 
                            titulo={props.titulo}
                            subtitulo={"EMPRESA: " + sessionStorage.getItem("RC_CODCOLIGADA") + " - " + sessionStorage.getItem("RC_NOMECOLIGADA")}
                        />

                        <Col className='text-right'>
                            {props.option}
                        </Col>
                    </Row>
                    {props.children}
                </Card.Body>
            </Card>
        </Container>
    );
}


