import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import Icone from '../Icone';

export default function Notificacao(props) {
    const [show, setShow] = useState(false);


    const style = {
        toast: {
            color: '#28a745',
            position: 'absolute',
            margin: '20px',
            zIndex: 99999,
            top: 0,
            right: 0
        },
        body: {
            display: 'flex'
        }
    }


    return (
        <>
            <button id='showNotificacaoSalva' visibility='false' onClick={() => setShow(true)} />

            <Toast style={style.toast} onClose={() => setShow(false)} show={show} delay={5000} autohide>
                <Toast.Header>
                    <strong className="mr-5">Mensagem de Aviso</strong>
                    <small>A menos de um segundo atrás</small>
                </Toast.Header>
                <Toast.Body style={style.body}>
                    <Icone icon='check' customClass='mr-2' />
                    <h6>{props.mensagem}</h6>
                </Toast.Body>
            </Toast >
        </>
    );
}