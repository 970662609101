import React from 'react';
import { Container, Card, Row } from 'react-bootstrap'
import CardTitulo from '../cardTitulo';
import BtnImpressao from '../btnImpressao';

export default function CardDetalhes(props) {
    return (
        <Container className="container-principal">
            <Card className="card-detalhe">
                <Card.Body>
                    <Row className="mt-3 mb-3">
                        <CardTitulo
                            titulo={props.titulo}
                            subtitulo={props.subtitulo}
                        />
                        <div 
                            style={{
                                fontSize: '25px',
                                margin: '0px 15px'
                            }}
                        >
                            <b>{props.valor}</b>
                        </div>
                        <BtnImpressao idmov={props.idmov} visible={props.btnImprimeVisible} disabled={props.disabled} />
                    </Row>
                    {props.children}
                </Card.Body>
            </Card>
        </Container>
    );
}