import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';

import Navbar from '../../components/navbar';
import CardDetalhes from '../../components/cardDetalhes';
import FormDetalheItem from '../../components/formDetalheItem'
import Loading from '../../components/loading';
import Rodape from '../../components/rodape';
import { MensagemErro } from '../../components/alerts';
import DataGrid from '../../components/datagrid';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { formataDinheiro, formataData } from '../../util/formatacao';

library.add(fas);

export default function DetalheItemMovimentoHooks(props) {
    const [dados, setDados] = useState(0);
    const [carregando, setCarregando] = useState(false);
    const [erro, setErro] = useState('');


    const colunasCotacoes = [
        {
            dataField: 'codcotacao',
            text: 'Cód. da Cotação',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'nome',
            text: 'Fornecedor',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'valorcotacao',
            text: 'Valor da Cotação',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value ?? 0),
            sort: true,
        },
        {
            dataField: 'valnegociado',
            text: 'Valor Negociado',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value ?? 0),
            sort: true
        },
        {
            dataField: 'observacao',
            text: 'Observação',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
    ];

    const colunasUltimasCompras = [
        {
            dataField: 'idmov',
            text: 'Id. Movimento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'nomefornecedor',
            text: 'Fornecedor',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'dataemissao',
            text: 'Data Emissão',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
        },
        {
            dataField: 'codund',
            text: 'Und.',
            headerAlign: 'center',
            align: 'center',
            sort: true,
        },
        {
            dataField: 'precounitario',
            text: 'Valor Unitário',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value ?? 0),
            sort: true,
        },
    ];

    const colunasCurvaABC = [
        {
            dataField: 'descism',
            text: 'Desc. Insumo',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'qtde',
            text: 'Quantidade',
            headerAlign: 'right',
            align: 'right',
            sort: true,
        },
        {
            dataField: 'qtdepedido',
            text: 'Quant. de Pedidos',
            headerAlign: 'right',
            align: 'right',
            sort: true,
        },
        {
            dataField: 'qtdenotas',
            text: 'Quant. de Notas',
            headerAlign: 'right',
            align: 'right',
            sort: true,
        },
        {
            dataField: 'qtdebaixa',
            text: 'Quant. Baixa Estoque',
            headerAlign: 'right',
            align: 'right',
            sort: true,
        },
        {
            dataField: 'qtdeemaprovacao',
            text: 'Quant. em Aprovação',
            headerAlign: 'right',
            align: 'right',
            sort: true,
        },
        {
            dataField: 'qtdeaprovada',
            text: 'Quant. Solicitada',
            headerAlign: 'right',
            align: 'right',
            sort: true,
        },
        {
            dataField: 'saldo',
            text: 'Saldo',
            headerAlign: 'right',
            align: 'right',
            sort: true,
        },
    ];

    useEffect(() => {
        async function getData() {
            setCarregando(true);

            let config = {
                headers: {
                    IDMOV: props.match.params.idmov,
                    NSEQITMMOV: props.match.params.nseq
                }
            }

            try {
                const response = await api.get('/DetalheItemMovimento', config);
                setDados(response.data);
            }
            catch (err) {
                setErro(`> ${err}`);
            }
            finally {
                setCarregando(false);
            }
        }
        getData();
    }, [props]);


    return (
        <>
            <Navbar />
            <CardDetalhes titulo="Detalhes do Item" subtitulo="Verifique todos os dados abaixo." btnImprimeVisible='false'>
                <MensagemErro mensagem={erro} />
                {carregando ?
                    <Loading />
                    :
                    <>
                        <FormDetalheItem
                            codprd={dados.codigoprd}
                            descricao={dados.nomefantasia}
                            valor={dados.valortotal}
                            codund={dados.codund}
                            dataentrega={dados.dataentrega}
                            valorsolicitacao={dados.valorsolicitacao}
                            historico={dados.historico}
                            centrocusto={dados.codccusto + ' - ' + dados.nomecentrocusto}
                            quantidade={dados.quantidade}
                            valorUnitario={dados.precounitario}
                            valorTotal={dados.valortotal}
                            idmov={dados.idmov}
                        />


                        <h4 className="mt-5">Cotações</h4>
                        <DataGrid
                            identificador="codcotacao"
                            colunas={colunasCotacoes}
                            dados={dados.cotacoes ?? ''}
                            carregando={carregando}
                            paginacao={true}
                        />


                        <h4 className="mt-5">Últimas Compras</h4>
                        <DataGrid
                            identificador="idmov"
                            dados={dados.ultimascompras ?? ''}
                            colunas={colunasUltimasCompras}
                            carregando={carregando}
                            paginacao={true}
                        />


                        <h4 className="mt-5">Curva ABC</h4>
                        <DataGrid
                            identificador="index"
                            dados={dados.curvaabc ?? ''}
                            colunas={colunasCurvaABC}
                            carregando={carregando}
                            paginacao={true}
                        />

                    </>
                }
                <div className="text-right">
                    <hr />
                    <Link to={`/detalheMovimento/${props.match.params.idmov}/${props.match.params.idaprovacao}/${props.match.params.statusMov}`} className="btn btn-primary mt-2 mr-1">
                        <FontAwesomeIcon className="mr-2" icon="arrow-left" />
                            Voltar ao Movimento
                    </Link>
                </div>
            </CardDetalhes>
            <Rodape />
        </>
    );
}
