import React from 'react';
import { Alert } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

export function MensagemErro(props) {
    return (
        props.mensagem !== '' ?
            <Alert variant="danger">
                <Alert.Heading>
                    <FontAwesomeIcon icon="exclamation-circle" className="mr-2" />
                    Ops... Ocorreu um erro ao processar sua solicitação :(
                </Alert.Heading>
                <hr />
                <p>{props.mensagem}</p>
            </Alert>
            : <></>
    );
}