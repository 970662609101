import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { Link } from 'react-router-dom';
import { Form, Col } from 'react-bootstrap';
import { Accordion, Card, Spinner, Alert } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

export default function TabelaClassificacao(props) {
    return (
        <>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                        <FontAwesomeIcon icon="sort-amount-down-alt" className="mr-2" />
                        Tabelas de Classificação
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <div className="container-fluid mt-3">
                        <Form.Row>
                {props.tblHeader1 != '-' &&
                    <Form.Group as={Col} md={4}>
                        <Form.Label htmlFor="tbl1">{props.tblHeader1}</Form.Label>
                        <Form.Control readOnly id="tbl1" value={props.tblDesc1} />
                    </Form.Group>
                }
                <br />
                <br />
                {props.tblHeader2 != '-' &&
                    <Form.Group as={Col} md={4}>
                        <Form.Label htmlFor="tbl2">{props.tblHeader2}</Form.Label>
                        <Form.Control readOnly id="tbl2" value={props.tblDesc2} />
                    </Form.Group>
                }
                <br />
                <br />
                {props.tblHeader3 != '-' &&
                    <Form.Group as={Col} md={4}>
                        <Form.Label htmlFor="tbl3">{props.tblHeader3}</Form.Label>
                        <Form.Control readOnly id="tbl3" value={props.tblDesc3} />
                    </Form.Group>
                }
                <br />
                <br />
                {props.tblHeader4 != '-' &&
                    <Form.Group as={Col} md={4}>
                        <Form.Label htmlFor="tbl4">{props.tblHeader4}</Form.Label>
                        <Form.Control readOnly id="tbl4" value={props.tblDesc4} />
                    </Form.Group>
                }
                <br />
                <br />
                {props.tblHeader5 != '-' &&
                    <Form.Group as={Col} md={4}>
                        <Form.Label htmlFor="tbl5">{props.tblHeader5}</Form.Label>
                        <Form.Control readOnly id="tbl5" value={props.tblDesc5} />
                    </Form.Group>
                }
            </Form.Row>
                        </div>
                    </Accordion.Collapse>
                </Card>
            </Accordion >
        </>
    );
}
