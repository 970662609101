import React, { Component } from 'react';
import Navbar from '../../components/navbar';
import { Link } from 'react-router-dom';
import {RedirectTo} from '../../util/utilLibrary';
import api from '../../services/api';

import { Form, FormGroup, Button, Spinner } from 'react-bootstrap'
import CardDetalhes from '../../components/cardDetalhes';
import Rodape from '../../components/rodape';
import FormDadosConfirmacao from '../../components/formDadosConfirmacao';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

class ReprovaMovimento extends Component {
    constructor(props) {
        super(props);

        this.state = {
            idMov: '',
            numMov: '',
            nomeFantasia: '',
            descricaoMotivo: '',
            usuarioMestre: '',
            dependencia: '',
            idAprovacao: '',
            descricao: '',
            codUsuarioOrigem: '',
            valorLiquido: '',
            motivos: [],
            ret: '',
            erro: '',
            reprovando: '',
            btnReprovaDisable: '',
            selectOptionDisabled: '',
        }
    }


    componentDidMount() {
        this.setState({
            idMov: this.props.match.params.idmov,
            numMov: this.props.match.params.numeromov,
            nomeFantasia: this.props.match.params.nomefantasia,
            valorLiquido: parseFloat(this.props.match.params.valor),
            usuarioMestre: this.props.match.params.usuariomestre,
            dependencia: this.props.match.params.dependencia,
            idAprovacao: this.props.match.params.idaprovacao,
            codUsuarioOrigem: this.props.match.params.codorigem,
            reprovando: false,
            btnReprovaDisable: true,
            selectOptionDisabled: false,
        });


        this.getMotivos();
    }


    //carrega os valores do select motivo
    getMotivos = async () => {

        try {
            //faz a requisição a Api
            const response = await api.get('/MotivoReprovacaoMovimento');
            this.setState({ motivos: response.data, motivo: response.data[0].descricao });
        }
        catch (err) {
            this.state.setState({ erro: 'erro ao carregar a lista de motivos' });
        }
    }


    //ao clicar em reprovação executa a api enviando os dados
    handleReprovaMov = async e => {
        e.preventDefault();

        this.setState({ reprovando: true, btnReprovaDisable: true })

        const { obs, desc } = this.state;


        let body = {
            "MOVIMENTO": [
                {
                    "CODCOLIGADA": sessionStorage.getItem("RC_CODCOLIGADA"),
                    "IDMOV": this.state.idMov,
                    "DESCMOTIVO": desc,
                    "CODUSUARIO": sessionStorage.getItem('RC_USR'),
                    "CODUSUARIOMESTRE": this.state.usuarioMestre,
                    "DEPENDENCIA": this.state.dependencia,
                    "IDAPROVACAO": this.state.idAprovacao,
                    "DESCRICAO": obs,
                    "CODUSUARIOORIGEM": this.state.codUsuarioOrigem,
                }
            ]
        };


        try {

            const response = await api.post('/ReprovarMovimentoObras', body);
            this.setState({ ret: response.data });


            //verifica se teve sucesso
            if (this.state.ret.CODIGO === '1') {
                RedirectTo('/movimentos', this.props.history);
            }
            else {

                this.setState({ reprovando: false, btnReprovaDisable: false, erro: this.state.ret.DESCRICAO });

            }
        }
        catch (err) {
            this.setState({ reprovando: false, btnReprovaDisable: false, erro: 'Erro ao efetuar reprovação, tente novamente mais tarde.' });
            console.log(err);
        }
    }


    //Altera no estado o motivo ao mudar no select
    handleOnChangeMotivo = (a) => { 
        this.setState({ desc: a.target.value, btnReprovaDisable: false, selectOptionDisabled: true }); 
    }


    //Renderiza o JSX na tela =========================================================
    render() {
        return (
            <div>
                <Navbar />

                <CardDetalhes titulo="Reprovação do movimento" subtitulo="Verifique se todos os dados abaixo estão corretos!" btnImprimeVisible="false">
                    {this.state.erro && <div className="alert alert-danger" role="alert">Erro: {this.state.erro} </div>}

                    <FormDadosConfirmacao
                        nummov={this.state.numMov}
                        nomefantasia={this.state.nomeFantasia}
                        valorliquido={this.state.valorLiquido}
                    />

                    <hr />
                    <Form onSubmit={this.handleReprovaMov}>
                        <FormGroup className="mt-4">

                            <Form.Label htmlFor="selectMotivo">Motivo da reprovação</Form.Label>
                            <Form.Control as="select" id="selectMotivo" onChange={this.handleOnChangeMotivo}>
                                <option key="000000" disabled={this.state.selectOptionDisabled} default>Selecione...</option>
                                {this.state.motivos.map((motivo) => (
                                    <option key={motivo.codcliente}> {motivo.descricao}</option>
                                ))}
                            </Form.Control>

                            <Form.Group className="mt-4">
                                <Form.Label htmlFor="textObservacao">Observações</Form.Label>
                                <Form.Control as="textarea" id="textObservacao" rows="5"
                                    onChange={e => this.setState({ obs: e.target.value })} required>
                                </Form.Control>
                            </Form.Group>

                        </FormGroup>
                        <hr />

                        <div className="text-right">
                            <Link to='/movimentos' className="btn btn-light mr-2">
                                Cancelar
                             </Link>

                            <Button type="submit" variant="danger" disabled={this.state.btnReprovaDisable}>
                                {this.state.reprovando ?
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            className="mr-2"
                                            role="status"
                                            aria-hidden="true"
                                        />

                                    Reprovando...</>

                                    :

                                    <>
                                        <FontAwesomeIcon icon="times-circle" className="mr-2" />
                                         Reprovar Movimento
                                    </>
                                }
                            </Button>
                        </div>
                    </Form>
                </CardDetalhes>

                <Rodape />

            </div>
        );
    }
}

export default ReprovaMovimento;