import React from 'react';
import DataGrid from '../../../../components/datagrid';
import { formataDinheiro } from '../../../../util/formatacao';


export default function Rateios(props) {

    const columns = [
        {
            dataField: 'codccusto',
            text: 'Centro de Custo',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'nome',
            text: 'Nome',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'valor',
            text: 'Valor',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value),
            sort: true,
        },
        {
            dataField: 'percentual',
            text: 'Percentual',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => value + '%',
            sort: true,
        }
    ];

    return (
        <>
            <h5 className="mt-4 mb-3" >Rateios</h5>

            <DataGrid
                identificador="codccusto"
                colunas={columns}
                dados={props.dados ?? ''}
                paginacao={true}
            />
        </>
    );
}