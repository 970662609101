import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);


export default function MenuItem(props) {
    return (
        <Link to={props.path} className="link-card">
            <div className="item-card shadow">
                <FontAwesomeIcon className="icon-menu" icon={props.icon} />
                <h4>{props.title}</h4>
                <p><strong>{props.subtitle}</strong></p>
            </div>
        </Link>
    );
}