import React, { useState, useEffect } from 'react'
import api from '../../services/apiMineracao';
import { FormatNumber, FormatDate2, FormatMoney } from '../../util/utilLibrary';

import { Button, Spinner } from 'react-bootstrap';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';


export default function FormProgramacaoRetira(props) {
    const [materiais, setMateriais] = useState('');
    const [carregando, setCarregando] = useState(false)
    const [materialSelecionado, setMaterialSelecionado] = useState('');
    const [programacao, setProgramacao] = useState('');

    useEffect(() => {
        if (props.show)
            GetProgramacao(props.item.idprogramacao);
        // eslint-disable-next-line
    }, [props.item])


    function GetProgramacao(idprogramacao) {
        setCarregando(true)
        const config = {
            headers: {
                "IDPROGRAMACAO": idprogramacao
            }
        }

        api.get('/EditaProgramacao', config)
            .then(resp => {
                resp = resp.data[0];
                setProgramacao(resp);

                const config = {
                    headers: {
                        "IDORCAMENTO": resp.idorcamento
                    }
                }

                api.get('/ListaPedidosItens', config)
                    .then(ped => {
                        let val = ped.data.filter(x => x.idprd === resp.idprd);
                        setMateriais(val);
                        setMaterialSelecionado(val[0]);
                    })
                    .catch(err => window.alert(err.toString()))
            })
            .catch(err => window.alert(err.toString()))
            .finally(() => setCarregando(false))
    }


    return (
        <>
            {!carregando ?
                <form className='form mt-4'>
                    <div className='form-row'>
                        <div className='form-group col-md-2'>
                            <TextBoxComponent name="refPedido" placeholder="Ref. Pedido" value={programacao.idprogramacao ?? 0}
                                floatLabelType='Always' readOnly />
                        </div>
                        <div className='form-group col-md-3'>
                            <TextBoxComponent name="numeroauxiliar" placeholder="Núm. Auxiliar" value={props.item.numeroauxiliar} floatLabelType='Always'
                                readOnly />
                        </div>
                        <div className='form-group col'>
                            <TextBoxComponent name="nomefantasia" placeholder="Nome do Cliente" value={props.item.nomefantasia ?? programacao.cliente}
                                floatLabelType='Always' readOnly />
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-group col'>
                            <TextBoxComponent name='endereco' placeholder="Endereço" floatLabelType='Always'
                                value={props.item.endereco ?? programacao.enderecoentrega} readOnly />
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-group col-md'>
                            <DropDownListComponent name='material' placeholder="Material*" dataSource={materiais} value={programacao.idprd}
                                fields={{ text: 'nomefantasia', value: 'idprd' }} floatLabelType='Always' enabled={false} />
                        </div>
                        <div className='form-group col-md-2'>
                            <TextBoxComponent name="quantt" placeholder="Quant. T" floatLabelType='Always'
                                readOnly value={FormatNumber(materialSelecionado.quantidadet ?? programacao.qtdeorc)} format='N3' />
                        </div>
                        <div className='form-group col-md-2'>
                            <TextBoxComponent name="quantentt" placeholder="Quant. Ent. T" floatLabelType='Always'
                                readOnly value={FormatNumber(materialSelecionado.quantidadeentreguet ?? programacao.qtdeentregueprd)} />
                        </div>

                    </div>

                    <div className='form-row'>
                        <div className='form-group col-md'>
                            <NumericTextBoxComponent name="quantprogram" locale='pt-BR' format='n2' min={0} decimals={2} readOnly
                                placeholder="Quant. Ton. Programada" floatLabelType='Always' value={programacao.quantidadet ?? 0} />
                        </div>
                        <div className='form-group col-md'>
                            <TextBoxComponent name="status" placeholder="Status" floatLabelType='Always' readOnly value={programacao.descStatus} />
                        </div>
                        <div className='form-group col-md'>
                            <TextBoxComponent name="feitoweb" placeholder="Feito pela Web?" floatLabelType='Always' readOnly value={programacao.mobile === 0 ? 'Não' : 'Sim'} />
                        </div>
                        <div className='form-group col-md'>
                            <TextBoxComponent name="dataprogramacao" value={FormatDate2(programacao.dataprogramacao)}
                                format="dd/MM/yyyy" placeholder="Data" floatLabelType='Always' readOnly />
                        </div>

                    </div>

                    <div className='form-row'>

                        <div className="form-group col-md-2">
                            <TextBoxComponent name="valorUnitario" type='text' placeholder='Valor Unitário' floatLabelType='Always'
                                value={FormatMoney(materialSelecionado.valorundprdt)} readonly />
                        </div>
                        <div className="form-group col-md-2">
                            <TextBoxComponent name="valorFrete" type='text' placeholder='Valor Frete' floatLabelType='Always'
                                value={FormatMoney(materialSelecionado.valorfrete)} readonly />
                        </div>
                        <div className="form-group col-md-2">
                            <TextBoxComponent name="valorTotal" type='text' placeholder='Valor total' floatLabelType='Always'
                                value={FormatMoney(materialSelecionado.precot)} readonly />
                        </div>

                        <div className='form-group col'>
                            <TextBoxComponent name="contato" type='text' placeholder='Contato' floatLabelType='Always'
                                value={programacao.contato} readOnly />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md">
                            <TextBoxComponent multiline={true} name="observacao" placeholder='Observação' floatLabelType='Always'
                                value={programacao.observacao} readOnly />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md">
                            <TextBoxComponent multiline={true} name="referenciaentrega" placeholder="Ref. da Entrega" floatLabelType='Always'
                                value={programacao.referenciaentrega} readOnly />
                        </div>
                    </div>


                    <div className='text-right'>
                        <Button variant='primary' onClick={() => props.handleClose()}>
                            Fechar
                        </Button>
                    </div>
                </form >

                : <div style={{ textAlign: 'center', margin: '30vh 0' }}>
                    <Spinner animation="border" variant="primary" />
                </div>
            }
        </>
    )
}