import React, { useEffect, useState } from 'react';
import api from '../../services/api'

import { Container,Tabs, Tab } from 'react-bootstrap';
import Navbar from '../../components/navbar';
import Icone from '../../components/Icone';

export default function Visao() {
    const [erro, setErro] = useState();
    const [relatorios, setRelatorios] = useState(true);
    const [carregando, setCarregando] = useState(true);


    useEffect(() => {
        GetDados();
        //eslint-disable-next-line
    }, [])


    function GetDados() {
        carregando ?? setCarregando(true)

        const usuario = sessionStorage.getItem('RC_USR_PORTAL');
        const hash = sessionStorage.getItem('RC_HASH');
        const alias = window.alias_RC;


        api.get(`/GetListaRelatorio?CODUSUARIO=${usuario}&HASH=${hash}&ALIAS=${alias}`)
            .then(resp => setRelatorios(resp.data))
            .catch(err => setErro(err.toString()))
            .finally(() => setCarregando(false));
    }

    return (
        <>
            <Navbar />

            <Container fluid className="container-card bg-white">
                        {erro &&
                            <div className="alert alert-danger" role="alert">
                                <Icone icon="times" customClass='mr-2' />
                                {erro}
                            </div>}
                        


                        {carregando ?
                            <p>Carregando</p>
                            :
                            <Tabs defaultActiveKey="1" id="tab-powerbi" className='pt-3'>
                                {relatorios.map((item) => (
                                    <Tab eventKey={item.sequencial} title={item.nomerelatorio}>

                                        <iframe
                                            title={item.nomerelatorio}
                                            width="100vw" height={"100vh"}
                                            style={{
                                                top: 0,
                                                width: "100%",
                                                marginTop: '10px',
                                                height: "calc(100vh - 110px)",
                                            }}
                                            scrolling={'no'}
                                            src={item.linkrelatorio}
                                            frameBorder={0}
                                            allowFullScreen={true}>
                                        </iframe>
                                    </Tab>
                                ))}
                            </Tabs>
                        }
            </Container>
        </>
    );
}
