import axios from 'axios';

//define a url base da API
const api = axios.create({
	baseURL: window.url_API,
});


//intercepta as requisições e injeta os valores abaixo no header.
api.interceptors.request.use(async config => {
	config.headers.ALIAS = window.alias_RC;
	config.headers.CODUSUARIO = sessionStorage.getItem("RC_USRMINERACAO");
	config.headers.CODCOLIGADA = sessionStorage.getItem("RC_CODCOLIGADA");
	config.headers.Authorization = `Bearer ${sessionStorage.getItem("RC_TOKEN")}`;
	config.headers.HASH = sessionStorage.getItem("RC_HASH") + Math.random();

	return config;
});


api.interceptors.response.use(response => {
	return response;
}, error => {
	if (error.response.status === 401) {
		sessionStorage.setItem("RC_COD", null);
		window.location = window.pasta;

		return Promise.reject(error);
	}
	else {
		return Promise.reject(error);
	}
})

export default api;