import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

const TituloModal = ({ variant = 'primary', titulo = 'Titulo inválido', icone = 'times' }) => (
    <>
        <FontAwesomeIcon icon={icone} className={'mr-2 text-' + variant} />
        {titulo}
    </>
)
export default TituloModal;