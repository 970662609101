import React from 'react';
import { Container } from 'react-bootstrap';
import Navbar from '../navbar';
//import Rodape from '../rodape';
import LayoutLoading from './layoutLoading';
import './style.css';

export default function LayoutDefault(props) {
    return (
        <>
            <Navbar/>
            {props.loading ? <LayoutLoading /> : <></>}
            <Container className="container-layout" fluid={props.fluid}>
                {props.children}
            </Container>
        </>
    );
}