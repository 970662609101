import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import './style.css';

export default function Erro404() {
    return (
        <Container>
            <div className="body-erro">
                <h1 className="h1-titulo mt-5">404 | Página não encontrada!</h1>
                <p className="text-muted subtitulo">Ops.. Parece que a página que você procura não existe no Portal RC.</p>
                <div className="text-left">
                    <Link to="/app" className="btn btn-dark mt-3">
                        Voltar ao Inicio
                    </Link>
                </div>
            </div>
        </Container>
    );
}