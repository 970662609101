import React, { useState } from 'react';
import { Button, Dropdown, Modal, Container, Form } from 'react-bootstrap';
import PackageJson from '../../../package.json'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

export default function ModalSobre() {

    //define os estados do modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const notaVersao = "========== PROGRAMACAO ========. \n" +
        " - Adicionado os campos de valor frete, unitario e total na tela de cadastro. \n" +
        " - Adicionado o campo de observação na tela de visão. \n" + 
        " - Removido a paginação da grid da visão. \n" +
        " - Adicionado os valores totais na tela de visão. \n" +
        " - Corrigido o problema com a data salva errada ao criar uma programação. \n" +
        " - Adicionado os filtros por status na tela de visão. \n" +
        " - Corrigido o problema com o perfil. \n" +
        " - Ajustes e correções diversas. \n" +
        " - Adicionado o recurso KPI que possibilita a visualização de relatórios em  Microsft PowerBI\n " +
        " - Adicionado o recurso de envio de emails\n ";
        

    //Renderiza o modal e o botão em tela ======================
    return (
        <div>
            <Dropdown.Item eventKey="1" onClick={handleShow} >
                <FontAwesomeIcon icon="info-circle" className=" mr-2" />
                   Sobre o sistema
           </Dropdown.Item>


            <Modal show={show} onHide={handleClose} backdrop="static" centered>
                <Modal.Body>
                    <Container>
                        <Form>
                            <h2 className="h2-titulo mt-3"><strong>PORTAL</strong> RC</h2>
                            <hr />
                            <p className="text-muted mt-3 mb-0">RC Consultoria em soluções inteligentes Ltda.</p>
                            <a className="mt-0" href="http://www.rcsolucoes.com.br" rel="noopener noreferrer" target="_blank">www.rcsolucoes.com.br</a>

                            <p className="text-muted mt-3 mb-0">Sistema: Portal RC</p>
                            <p className="text-muted mb-0">Versão: {PackageJson.version}</p>
                            <p className="text-muted mb-0">Versão do serviço: {sessionStorage.getItem("RC_VERSAOSERVICE")}</p>
                            <p className="text-muted mb-0">Alias: {window.alias_RC}</p>
                            <p className="text-muted mb-0">Usuário Logado: {sessionStorage.getItem("RC_USR")}</p>
                            <p className="text-muted mb-3">Empresa: {sessionStorage.getItem("RC_CODCOLIGADA") + ' - ' + sessionStorage.getItem("RC_NOMECOLIGADA")}</p>

                            <Form.Group className="mb-2" controlId="textAreaNotaVersao">
                                <Form.Label className="text-muted">Notas de versão</Form.Label>
                                <Form.Control as="textarea" rows="5" value={notaVersao} readOnly />
                            </Form.Group>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}