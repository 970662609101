import React, { useState, useEffect } from 'react';
import api from '../../services/apiMineracao';
import { getData } from '../../util/getData';

import { ColumnDirective, ColumnsDirective, GridComponent, Sort, Inject, Filter } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';

import { Container, Card, Row, Col, Badge, InputGroup, Form, FormControl, Button, ButtonGroup } from 'react-bootstrap';
import CardTitulo from '../../components/cardTitulo';
import Navbar from '../../components/navbar'
import Icone from '../../components/Icone';
import ModalEdicao from './edicao';
import ModalEdicaoRetira from './edicaoRetira';
import ModalExclusao from './exclusao';
import CardTotais from '../../components/programacao/cardTotais';
import Notificacao from '../../components/programacao/notificacao';

import estilos from "../../estiloTabelas.css";


export default function VisaoProgramacao() {
    const [dados, setDados] = useState(['']);
    const [dadosRetira, setDadosRetira] = useState(['']);
    const [data, setData] = useState(sessionStorage.getItem('DATAPROGRAMACAOVISAO') ?? getData());
    const [erro, setErro] = useState('');
    const [selecionado, setSelecionado] = useState('');
    const [isEntrega, setIsEntrega] = useState(true);
    const [Grid, setGrid] = useState('');
    const [statusSelecionado, setStatusSelecionado] = useState(sessionStorage.getItem('statusprogramacao') ?? 0);

    const statusData = [
        { id: 0, desc: 'Em Aberto / Suspensos' },
        { id: 1, desc: 'Finalizado' },
        { id: 2, desc: 'Cancelados' },
        { id: 3, desc: 'Todos' }
    ];


    L10n.load({
        'pt-BR': {
            'grid': {
                'EmptyRecord': 'Nenhum valor localizado',
            },
            'pager': {
                'currentPageInfo': '{0} de {1} páginas',
                'firstPageTooltip': 'Primeira página',
                'lastPageTooltip': 'Ultima página',
                'nextPageTooltip': 'Próxima página',
                'previousPageTooltip': 'Página anterior',
                'totalItemsInfo': '({0} itens)'
            }
        }
    });


    useEffect(() => {
        erro && setErro('')
        selecionado && setSelecionado('');
        sessionStorage.setItem('DATAPROGRAMACAOVISAO', data)
        GetDados(data);

        //eslint-disable-next-line
    }, [data, statusSelecionado, isEntrega]);


    function GetDados() {
        dados && setDados([''])
        dadosRetira && setDadosRetira([''])
        selecionado && setSelecionado('')

        const config = {
            headers: {
                "STATUS": statusSelecionado,
                "DATAPROGRAMACAO": sessionStorage.getItem('DATAPROGRAMACAOVISAO') ?? getData()
            }
        }
        api.get(isEntrega ? '/ListaProgramacao' : '/ListaProgramacaoRetira', config)
            .then(resp => {
                isEntrega ? setDados(resp.data) : setDadosRetira(resp.data);
            })
            .catch(err => setErro(err.toString()))
    }


    const FilterSettingsModel = {
        type: 'Menu'
    };


    const quantProgramadaTemplate = (props) => (
        <strong className={props.qtdeagendado > 0 ? 'text-warning' : undefined}>{props.qtdeagendado}</strong>
    )


    const quantEnviadaTemplate = (props) => (
        <strong className={props.qtdenotaenviada > 0 ? 'text-success' : undefined}>{props.qtdenotaenviada}</strong>
    )


    function statusTemplate(props) {
        let descricao;
        let cor;

        switch (props.status)
        {
            case 'F':
                descricao = 'Finalizado';
                cor = 'success';
                break;

            case 'C':
                descricao = 'Cancelado';
                cor = 'danger';
                break;

            case 'B':
                descricao = 'Bloqueado';
                cor = 'danger';
                break;

            case 'V':
                descricao = 'Finalizada Manualmente';
                cor = 'warning';
                break;

            case 'R':
                descricao = 'Recuperada';
                cor = 'warning';
                break;

            case 'A':
                descricao = 'Em Aberto';
                cor = 'primary'
                break;

            case 'S':
                descricao = 'Suspensa';
                cor = 'danger';
                break;

            default:
                descricao = 'Desconhecido';
                cor = "secondary"
                break;
        }
        return (<Badge variant={cor}>{descricao}</Badge>);
    }


    function search(e) {
        e.preventDefault();

        let serchText = e.target.searchbox.value;
        (Grid) && Grid.search(serchText);
    }


    function handleDoubleClick(e) {
        let button = document.getElementById('btnEditarProgramacao');
        button.click();
    }

    function handleDoubleClickRetira(e) {
        let button = document.getElementById('btnEditarProgramacaoRetira');
        button.click();
    }

    return (
        <>
            <Notificacao mensagem='Programação salva com Sucesso!' />

            <Navbar />

            <Container fluid className="container-card">
                <Card className="card-visao">
                    <Card.Body>
                        {erro &&
                            <div className="alert alert-danger" role="alert">
                                <Icone icon="times" customClass='mr-2' />
                                {erro}
                            </div>}
                        <Row className="mt-2 mb-1">
                            <Col className="mt-2 mb-1 md-4">
                                <CardTitulo
                                    titulo={`Visão de Programação - ${isEntrega ? 'Entrega' : 'Retira'}`}
                                    subtitulo={"EMPRESA: " + sessionStorage.getItem("RC_CODCOLIGADA") + " - " +
                                        sessionStorage.getItem("RC_NOMECOLIGADA")} />
                            </Col>

                            <Col md>
                                <CardTotais data={data} />
                            </Col>
                        </Row>

                        <Row>
                            <Col className='mb-2'>
                                {isEntrega ?
                                    <ButtonGroup>
                                        <ModalEdicao
                                            atualizaVisao={() => GetDados()}
                                            item={selecionado ?? ''}
                                            status={selecionado.status} />

                                        <ModalExclusao
                                            atualizaVisao={() => GetDados()}
                                            idprogramacao={selecionado.idprogramacao}
                                            status={selecionado.statusnfe === 'T' && selecionado.editavel === 1} />
                                    </ButtonGroup>
                                    :
                                    <ModalEdicaoRetira atualizaVisao={() => GetDados()}
                                        item={selecionado ?? ''}
                                        status={selecionado.status} />
                                }
                            </Col>

                            <Col md>
                                <Form onSubmit={e => search(e)}>


                                    <Form.Row>
                                        <fieldset>
                                            <Form.Group as={Col} className='mt-2'>
                                                <Row>
                                                    <Form.Check
                                                        type="radio"
                                                        label="Entrega"
                                                        className='mr-2'
                                                        defaultChecked={true}
                                                        value={isEntrega === true}
                                                        onClick={() => setIsEntrega(true)}
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios1"
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label="Retira"
                                                        className='mr-2'
                                                        value={isEntrega === false}
                                                        onClick={() => setIsEntrega(false)}
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios2"
                                                    />
                                                </Row>
                                            </Form.Group>
                                        </fieldset>

                                        <Form.Group as={Col} md={3} controlId="dataProgramacao">
                                            <Form.Control type='date' value={data} onChange={e => setData(e.target.value)} />
                                        </Form.Group>

                                        <Form.Group as={Col} md={4} controlId="status">
                                            <FormControl as="select" value={statusSelecionado}
                                                onChange={e => { sessionStorage.setItem("statusprogramacao", e.target.value); setStatusSelecionado(e.target.value) }}>
                                                {statusData.map((statusProg) => (
                                                    <option value={statusProg.id}>{statusProg.desc}</option>
                                                ))}
                                            </FormControl>
                                        </Form.Group>

                                        <Form.Group as={Col} md>
                                            <InputGroup>
                                                <FormControl
                                                    placeholder="Pesquisar"
                                                    name='searchbox'
                                                />
                                                <InputGroup.Append>
                                                    <Button type='submit' variant="light" style={{ border: '1px solid #ced4da' }}>
                                                        <Icone icon="search" />
                                                    </Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                            </Col>
                        </Row>

                        {isEntrega ?
                            <GridComponent dataSource={dados} ref={grid => setGrid(grid)} locale='pt-BR' allowSorting={true} height='60vh'
                                filterSettings={FilterSettingsModel} rowSelected={e => setSelecionado(e.data)} recordDoubleClick={handleDoubleClick}>
                                <ColumnsDirective>
                                    <ColumnDirective field='numeroauxiliar' headerText="Nº Auxiliar" width='110' />
                                    <ColumnDirective field='status' headerText="Status" template={statusTemplate} width='120' textAlign="center" />
                                    <ColumnDirective field='statusdata' headerText="Status Data" />
                                    <ColumnDirective field='cliente' width='450' headerText="Cliente" />
                                    <ColumnDirective field='produto' headerText="Produto" width='160' />
                                    <ColumnDirective field="nomeVendedor" headerText="Vendedor" width='160' />
                                    <ColumnDirective field='observacao' headerText="Observação" width='240' />
                                    <ColumnDirective field='qtdeagendado' headerText="Aguardo" width='90' textAlign="Right" template={quantProgramadaTemplate} />
                                    <ColumnDirective field='qtdenotaenviada' headerText="Notas" width='50' textAlign="Right" template={quantEnviadaTemplate} />
                                    <ColumnDirective field='quantidadet' headerText="Qtde. Programado" width='140' format='N2' textAlign="Right" />
                                    <ColumnDirective field='qtdeentreguet' headerText="Qtde. Entregue" width='140' format='N2' textAlign="Right" />
                                    <ColumnDirective field='saldorestante' headerText="Saldo" width='70' format='N2' textAlign="Right" />
                                </ColumnsDirective>
                                <Inject services={[Sort, Filter]} />
                            </GridComponent>
                            :
                            <GridComponent dataSource={dadosRetira} ref={grid => setGrid(grid)} locale='pt-BR' allowSorting={true} height='60vh'
                                filterSettings={FilterSettingsModel} rowSelected={e => setSelecionado(e.data)} recordDoubleClick={handleDoubleClickRetira}>
                                <ColumnsDirective>
                                    <ColumnDirective field='numeroauxiliar' headerText="Nº Auxiliar" width='110' />
                                    <ColumnDirective field='status' headerText="Status" template={statusTemplate} width='120' textAlign="center" />
                                    <ColumnDirective field='statusdata' headerText="Status Data" />
                                    <ColumnDirective field='cliente' width='450' headerText="Cliente" />
                                    <ColumnDirective field='produto' headerText="Produto" width='160' />
                                    <ColumnDirective field="nomeVendedor" headerText="Vendedor" width='160' />
                                    <ColumnDirective field='observacao' headerText="Observação" width='240' />
                                    <ColumnDirective field='qtdeagendado' headerText="Aguardo" width='90' textAlign="Right" template={quantProgramadaTemplate} />
                                    <ColumnDirective field='qtdenotaenviada' headerText="Notas" width='50' textAlign="Right" template={quantEnviadaTemplate} />
                                    <ColumnDirective field='quantidadet' headerText="Qtde. Programado" width='140' format='N2' textAlign="Right" />
                                    <ColumnDirective field='qtdeentreguet' headerText="Qtde. Entregue" width='140' format='N2' textAlign="Right" />
                                </ColumnsDirective>
                                <Inject services={[Sort, Filter]} />
                            </GridComponent>
                        }
                    </Card.Body>
                </Card>
                <div id="modalVisao"></div>
            </Container>
        </>
    );
}