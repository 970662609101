import cryptojs from 'crypto-js';

export function Criptografa(text) {
    let encryptedValue = '';

    if (text !== '') {
        encryptedValue = cryptojs.MD5(text)
            .toString()
            .toUpperCase();
    }

    return encryptedValue;
}