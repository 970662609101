import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { formataData, formataDinheiro } from '../../../../util/formatacao';

export default function FormDetalheMovimento(props) {
    return (
        <Form>
            <Form.Row>
                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputIdMov">Num. Movimento</Form.Label>
                    <Form.Control readOnly id="inputIdMov" value={props.numeromov} />
                </Form.Group>

                <Form.Group as={Col} md={6}>
                    <Form.Label htmlFor="inputNomeFantasia">Nome Fantasia</Form.Label>
                    <Form.Control readOnly id="inputNomeFantasia" value={props.nomefantasia} />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label htmlFor="inputDataEmissao">Data de Emissão</Form.Label>
                    <Form.Control readOnly id="inputDataEmissao" value={formataData(props.dataemissao)} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md={6}>
                    <Form.Label htmlFor="inputTipoMovimento">Tipo de Movimento</Form.Label>
                    <Form.Control readOnly id="inputTipoMovimento" value={props.tipomov} />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label htmlFor="inputNomeComprador">Nome do Comprador</Form.Label>
                    <Form.Control readOnly id="inputNomeComprador" value={props.nomecomprador} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputIdMov">Id. Movimento</Form.Label>
                    <Form.Control readOnly id="inputIdMov" value={props.idmov} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputCondPag">Condição de Pag.</Form.Label>
                    <Form.Control readOnly id="inputCondPag" value={props.condicaopagamento} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputQuantItens">Quant. de Itens</Form.Label>
                    <Form.Control readOnly id="inputQuantItens" value={props.quantidadeitens} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputVlrBruto">Valor Bruto</Form.Label>
                    <Form.Control readOnly id="inputQuantItens"
                        value={formataDinheiro(props.valorbruto ?? 0)} />
                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputVlrFrete">Valor do Frete</Form.Label>
                    <Form.Control readOnly id="inputVlrFrete" value={formataDinheiro(props.valorfrete ?? 0)} />

                </Form.Group>

                <Form.Group as={Col} md={2}>
                    <Form.Label htmlFor="inputVlrLiquido">Valor Líquido</Form.Label>
                    <Form.Control readOnly id="inputVlrLiquido" value={formataDinheiro(props.valorliquido ?? 0)} />
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label htmlFor="inputObservacao">Observações</Form.Label>
                    <Form.Control as="textarea" rows="5" readOnly id="inputObservacao" value={props.observacao} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label htmlFor="inputHistorico">Histórico</Form.Label>
                    <Form.Control as="textarea" rows="5" readOnly id="inputHistorico" value={props.historico} />
                </Form.Group>
            </Form.Row>
        </Form>
    );
} 