import React from 'react';
import { Badge } from 'react-bootstrap';
import './style.css';

export default function BadgeStatusMov(props) {

    if (props !== undefined) {
        let type;
        let customClass;

        switch (props.status) {
            case "Aprovado":
                type = "success";
                break;

            case "Em Aprovação":
                type = "primary";
                break;

            case "Reavaliar":
                type = 'warning';
                break;

            case "Reavaliado":
                customClass = "badge badge-orange"
                break;

            case "Reprovado":
                type = "danger";
                break;

            default:
                type = "dark";
                break;
        }

        return (
            <Badge variant={type} className={customClass}>
                {props.status}
            </Badge>
        );
    }

}