import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { formataDinheiro, formataData } from '../../../../util/formatacao';

import { Card, Col, Row } from 'react-bootstrap';


export default function CardTotais(props) {
    const [dados, setDados] = useState('');

    useEffect(() => {
        if (props.carregando === true) {
            getTotais(props.dataInicial, props.dataFinal);
        }
    }, [props]);


    async function getTotais(dataInicial, dataFinal) {
        let config = {
            headers: {
                "CODUSUARIO": sessionStorage.getItem('RC_USR'),
                "DATAINICIAL": formataData(dataInicial),
                "DATAFINAL": formataData(dataFinal),
            }
        }

        const response = await api.get('/ResumoAprovacaoFinanceira', config);
        setDados(response.data[0] ?? '');
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Valores totais pelo período selecionado</Card.Title>
                <Row>
                    <Col>
                        <Card.Text> Total para Aprovação: {formataDinheiro(dados.naoaprovado ?? 0)}</Card.Text>
                    </Col>
                    <Col>
                        <Card.Text> Total Aprovados: {formataDinheiro(dados.aprovado ?? 0)}</Card.Text>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}