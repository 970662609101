import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { logout } from "../../services/auth";

import { Alert } from 'react-bootstrap';
import Rodape from '../../components/rodape'
import CardDetalhes from '../../components/cardDetalhes';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import DataGrid from '../../components/datagrid';
import StatusColigada from './statusColigada';
import ModalAlteraSenha from '../../components/modalAlteraSenha';

library.add(fas);


export default function SelecaoColigada(props) {
    const [listaColigada, setListaColigada] = useState([]);
    const [carregando, setCarregando] = useState(true);
    const [erro, setErro] = useState('');


    const colunas = [
        {
            dataField: 'botoes',
            text: '',
            headerAlign: 'left',
            align: 'left',
            sort: false,
        },
        {
            dataField: 'codcoligada',
            text: 'Código',
            headerAlign: 'center',
            align: 'center',
            sort: false,
        },
        {
            dataField: 'nomefantasia',
            text: 'Empresa',
            headerAlign: 'left',
            align: 'left',
            sort: false,
        },
        {
            dataField: 'aguardandoAprov',
            text: 'Aguardando Aprovação',
            headerAlign: 'center',
            align: 'center',
            sort: false,
        },
        {
            dataField: 'cgc',
            text: 'CNPJ',
            headerAlign: 'right',
            align: 'right',
            sort: false,
        },
    ];

    useEffect(() => {
        async function getDados() {

            let config = {
                headers: {
                    CODUSUARIO: sessionStorage.getItem('RC_USR')
                }
            }

            try
            {
                const response = await api.get('/SelecionaEmpresaObras', config);
                let coligadas = response.data.map((col) => {
                    return ({
                        ...col,
                        botoes: <Link to={`/app`} onClick={() => { sessionStorage.setItem('RC_CODCOLIGADA', col.codcoligada); sessionStorage.setItem('RC_NOMECOLIGADA', col.nomefantasia); sessionStorage.setItem('RC_STATUSVISAO', "0"); }}
                            className="btn btn-outline-primary btn-sm btn-grid mr-1" data-placement="right">
                            Selecionar
                        </Link>,
                        aguardandoAprov: <StatusColigada mov={col.temmov} fin={col.temfin} />
                    });
                })

                setListaColigada(coligadas);
            }
            catch (err)
            {
                setErro(err.toString());
            }
            finally
            {
                setCarregando(false);
            }
        }
        getDados();
        showModalAlteraSenha()
    }, [props]);


    const showModalAlteraSenha = () => {
        if (sessionStorage.getItem('RC_ALTPSW') === 'T')
        {
            document.getElementById('btnAlteraSenha').click();
        }
    }


    return (
        <div>

            <ModalAlteraSenha hideButton={true} />

            <CardDetalhes titulo="Seleção de Empresa" subtitulo="Selecione abaixo uma empresa para continuar..." btnImprimeVisible='false'>
                {erro && <Alert variant="danger"> Erro ao baixar dados: {erro} </Alert>}
                <DataGrid
                    identificador="codcoligada"
                    colunas={colunas}
                    dados={listaColigada}
                    carregando={carregando}
                />

                <hr />
                <div className="text-right">
                    {props.match.params.primeiroAcesso === "0" ?
                        <Link to={'/'} className="btn btn-danger" onClick={() => logout()}
                            data-placement="right" title="Sai do RC Aprovação">
                            <FontAwesomeIcon icon="power-off" className="mr-2" />
                            Encerrar Sessão
                        </Link>
                        :
                        <Link to={'/app'} className="btn btn-primary" data-placement="right" title="Sai do Portal RC">
                            Cancelar
                        </Link>
                    }
                </div>
            </CardDetalhes>
            <Rodape />
        </div>
    );
}