import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';

import { Button } from 'react-bootstrap';
import { MensagemErro } from '../../components/alerts';
import Icone from '../../components/Icone';

import FormDetalheLancamento from './components/formDetalheLanc';
import ModalAprovMultipla from '../../components/ModalAprovMultipla'
import Rateios from './components/Rateios';
import FormDetalheMovimento from './components/formDetalheMov';
import LancVinculados from './components/lancVinculados';
import ItensMovimento from './components/ItensMovimento';
import { formataDinheiro } from '../../util/formatacao';

import ModalReavaliaLanc from '../../components/modalReavaliaLanc';
import Rodape from '../../components/rodape';
import Navbar from '../../components/navbar';
import Loading from '../../components/loading';
import CardDetalhes from '../../components/cardDetalhes';


export default function DetalheLancamento(props) {
    const [dados, setDados] = useState(['']);
    const [dadosMov, setDadosMov] = useState(['']);
    const [carregando, setCarregando] = useState(false);
    const [erro, setErro] = useState('');
    const [detalheLan, setDetalheLan] = useState('');

    const history = useHistory();


    useEffect(() => {
        GetDados(props.match.params.idlan);
        GetDetalhesLan(props.match.params.idlan);
    }, [props])

    async function GetDetalhesLan(idlan) {
        let config = {
            headers: {
                'CODCOLIGADA': sessionStorage.getItem('RC_CODCOLIGADA'),
                'IDLAN': idlan,
            }
        }

        try {
            const response = await api.get('/HistoricoEnvioAprovacao', config);

            const dados = response.data;
            setDetalheLan(dados);
        }
        catch (err) {
            setErro(err.toString());
        }
        finally {
            setCarregando(false)
        }
    }

    async function GetDados(idlan) {
        setCarregando(true)
        setErro('')

        let config = {
            headers: {
                'CODCOLIGADA': sessionStorage.getItem('RC_CODCOLIGADA'),
                'IDLAN': idlan,
            }
        }

        try {
            const response = await api.get('/DetalheLancamento', config);

            const dados = response.data;
            setDados(dados);

            const dadosMovimento = dados.hasOwnProperty("detalhelanmov") ? dados.detalhelanmov[0] : '';
            setDadosMov(dadosMovimento);
        }
        catch (err) {
            setErro(err.toString());
        }
        finally {
            setCarregando(false)
        }
    }

    return (
        <div>
            <Navbar />
            <CardDetalhes titulo="Detalhes do Lançamento Financeiro" subtitulo="Verifique todos os dados abaixo."
                btnImprimeVisible={false} disabled="" valor={"Valor: " + formataDinheiro(dados.valor)} idmov="{props.match.params.idmov}">
                <MensagemErro mensagem={erro} />
                {carregando !== true ?

                    <>
                        <FormDetalheLancamento
                            idlan={dados.idlan}
                            dataaprovador={dados.dataaprovador}
                            nomeaprovador={dados.nomeaprovador}
                            dataprevbaixa={dados.dataprevbaixa}
                            datavencimento={dados.datavencimento}
                            dataemissao={dados.dataemissao}
                            dataenvio={dados.dataenvio}
                            numerodocumento={dados.numerodocumento}
                            tipoDocto={dados.tipoDocto}
                            planoFinacneiro={dados.planoFinacneiro}
                            empresaFc={dados.empresaFc}
                            empresaResp={dados.empresaResp}
                            codcfo={dados.codcfo}
                            fornecedor={dados.fornecedor}
                            historico={dados.historico}
                            valor={dados.valor}
                            idmov={dados.idmov}
                            aprovadorcompra={dados.aprovadorcompra}
                            temMov={dadosMov}
                            detalheLan={detalheLan}
                        />

                        <Rateios
                            dados={dados.detalhelanrateio} />


                        {dadosMov ?
                            <>
                                <h5 className="mt-4 mb-3" >Dados do Movimento</h5>
                                <hr />

                                <FormDetalheMovimento
                                    numeromov={dadosMov.numeromov ?? '-'}
                                    nomefantasia={dadosMov.nomefantasia ?? '-'}
                                    dataemissao={dadosMov.dataemissao ?? '-'}
                                    tipomov={dadosMov.codtmv + " - " + dadosMov.descTmv ?? '-'}
                                    idmov={dadosMov.idmov ?? '-'}
                                    descCpg={dadosMov.descCpg ?? '-'}
                                    valorbruto={dadosMov.valorbruto}
                                    valorliquido={dadosMov.valorliquido}
                                    valorfrete={dadosMov.valorfrete}
                                    observacao={dadosMov.observacao ?? ''}
                                    criadopor={dadosMov.criadopor ?? ''}
                                    criadoem={dadosMov.criadoem ?? ''}
                                    historico={dadosMov.historico ?? ''}
                                    modificadopor={dadosMov.modificadopor ?? ''}
                                    modificadoem={dadosMov.modificadoem ?? ''}
                                    aprovadopor={dados.nomeaprovador}
                                    useraprovacao1={dadosMov.useraprovacao1}
                                    useraprovacao2={dadosMov.useraprovacao2}
                                    useraprovacao3={dadosMov.useraprovacao3}
                                />


                                <ItensMovimento
                                    dados={dados.detalhelanmovitem} />


                                <LancVinculados
                                    dados={dados.detalhelanlancamento ?? ''}
                                    carregando={carregando}
                                    status={dados.status}
                                    idlanAtual={dados.idlan} />

                            </>
                            :
                            <>
                                <div className="text-right">
                                    <Button variant={'primary'} className='mr-2' onClick={() => history.goBack()}>
                                        <Icone icon="arrow-left" customClass="mr-1" />
                                        Voltar
                                    </Button>

                                    {dados.status !== 'F' && !carregando ?
                                        <>
                                            <ModalReavaliaLanc
                                                visao={false}
                                                idlan={dados.idlan} />

                                            <ModalAprovMultipla
                                                visao={false}
                                                lacamentos={''}
                                                idlanAtual={dados.idlan} />
                                        </>
                                        :
                                        <>
                                            <Button variant="warning" disabled className="mr-2">
                                                <Icone icon="thumbs-down" />
                                                &nbsp; Reavaliar
                                            </Button>

                                            <Button variant="success" disabled className="mr-2">
                                                <Icone icon="check" />
                                                &nbsp; Aprovar
                                            </Button>
                                        </>
                                    }
                                </div>
                            </>}
                    </>
                    :
                    <div>
                        <Loading />
                    </div>
                }
            </CardDetalhes>
            <Rodape />
        </div>
    );
}