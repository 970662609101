import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { getDataAtualMov, getDataMov, getDataMenosDiasMov } from '../../util/getData';
import moment from 'moment';

import { formataData, formataDinheiro } from '../../util/formatacao'

import { Container, Form, Card, Row, Button, Col, Alert } from 'react-bootstrap';
import CardTitulo from '../../components/cardTitulo';
import BadgeStatusMov from '../../components/badgeStatusMov';
import Navbar from '../../components/navbar';
import Rodape from '../../components/rodape';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BtnVisao from './components/btnVisao';

import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);


export default function VisaoAprovacao(props) {
    const [ultimaAtualizacao, setUltimaAtualizacao] = useState('');
    const [btnAtualizaDisable, setBtnAtualizaDisable] = useState(true);
    const [carregando, setCarregando] = useState(true);
    const [dados, setDados] = useState([]);
    const [semPedidoMae, setSemPedidoMae] = useState([]);
    const [erro, setErro] = useState('');
    const [status, setStatus] = useState(sessionStorage.getItem("RCSTATUSFIN") ? sessionStorage.getItem("RCSTATUSFIN") : 'T');
    // const [dataInicialInput, setDataInicialInput] = useState();
    const [dataFinal, setDataFinal] = useState(getDataMov());
    const [dataInicial, setDataInicial] = useState(getDataMenosDiasMov(30));
    const [ordenacao, setOrdenacao] = useState({ field: localStorage.getItem('col') ?? 'id', order: localStorage.getItem('ord') ?? 'asc' });
    const formataData = (dataEntrada) => {
        // console.log("---------------------");

        let ano = dataEntrada.substr(0, 4);
        let mes = dataEntrada.substr(5, 2);
        let dia = dataEntrada.substr(8, 2);

        let dataFormatada = dia + "/" + mes + "/" + ano;

        // console.log("data entrada ->", dataEntrada);

        // console.log("dia ->", dia);
        // console.log("mes ->", mes);
        // console.log("ano ->", ano);
        // console.log("data final ->", dataFormatada);

        return dataFormatada;
    }

    const handleChangeDataInicial = (e) => {
        setDataInicial(e.target.value);
    }

    const handleChangeDataFinal = (e) => {
        setDataFinal(e.target.value);
    }
    // console.log(dataInicial);
    const { SearchBar } = Search;

    const colunas = [
        {
            dataField: 'botoes',
            text: '',
            headerAlign: 'left',
            align: 'left',
            sort: false,
        },
        {
            dataField: 'numeromov',
            text: 'Num. Movimento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => <BadgeStatusMov status={value !== undefined ? value : '-'} />,
            sort: true,
        },
        {
            dataField: 'tipomov',
            text: 'Tipo de Movimento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'nomefantasia',
            text: 'Nome Fantasia',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'dataemissao',
            text: 'Data Emissão',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
        },
        {
            dataField: 'nomeVendedor',
            text: 'Comprador',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'emitente',
            text: 'Solicitante',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'nomeCcusto',
            text: 'Centro de Custo',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'valorliquido',
            text: 'Valor',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value),
            sort: true,
        },
    ];

    useEffect(() => {
        sessionStorage.getItem('RC_CODCOLIGADA') === 'A' ?
            props.history.push('/SelecionaEmpresa/0') : getDados(sessionStorage.getItem('RC_STATUSVISAO'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);



    async function getDados(status) {
        //remove a mensagem de erro ao atualizar os dados e desabilita o btn de atualizar visao
        setErro('');
        setBtnAtualizaDisable(true);
        setCarregando(true);
        sessionStorage.setItem('RC_STATUSVISAO', status);

        let config = {
            headers: {
                'CODUSUARIO': sessionStorage.getItem('RC_USR'),
                'STATUSAPROVACAO': status,
                'DATAINICIAL': formataData(dataInicial),
                'DATAFINAL': formataData(dataFinal),
                'Authorization': sessionStorage.getItem('RC_TOKEN'),
                'HASH': sessionStorage.getItem('RC_HASH'),
            }
        }

        try {
            const response = await api.get('/AprovacaoMovObras', config);

            console.log(response);

            let dadosProcessados = response.data.map((mov) => {
                const pedidoMae = mov.pedidomae === null ?
                    setSemPedidoMae((pedidoMae) => [...pedidoMae, mov.idmov]) : mov.pedidomae
                return ({
                    botoes: <BtnVisao
                        idmov={mov.idmov}
                        numeromov={mov.numeromov}
                        idaprovacao={mov.idaprovacao}
                        nomefantasia={mov.nomefantasia}
                        valorliquido={mov.valorliquido}
                        codtmv={mov.codtmv}
                        usuariomestre={mov.usuariomestre}
                        dependencia={mov.dependencia}
                        sequencial={mov.sequencial}
                        codusuario={mov.codusuario}
                        nomeVendedor={mov.nomeVendedor}
                        status={mov.statusmovimento}
                        tblHeader1={mov.nometabfat1 ? mov.nometabfat1 : "-"}
                        tblHeader2={mov.nometabfat2 ? mov.nometabfat2 : "-"}
                        tblHeader3={mov.nometabfat3 ? mov.nometabfat3 : "-"}
                        tblHeader4={mov.nometabfat4 ? mov.nometabfat4 : "-"}
                        tblHeader5={mov.nometabfat5 ? mov.nometabfat5 : "-"}
                        tblDesc1={mov.descricaoftb1 ? mov.descricaoftb1 : "-"}
                        tblDesc2={mov.descricaoftb3 ? mov.descricaoftb2 : "-"}
                        tblDesc3={mov.descricaoftb3 ? mov.descricaoftb3 : "-"}
                        tblDesc4={mov.descricaoftb4 ? mov.descricaoftb4 : "-"}
                        tblDesc5={mov.descricaoftb5 ? mov.descricaoftb5 : "-"}
                    />
                    ,
                    idmov: mov.idmov,
                    status: mov.statusmovimento,
                    tipomov: mov.codtmv + '-' + mov.descTmv,
                    numeromov: mov.numeromov,
                    nomefantasia: mov.nomefantasia ?? '-',
                    dataemissao: mov.dataemissao,
                    nomeVendedor: mov.nomeVendedor ?? '-',
                    emitente: mov.emitente ?? '',
                    nomeCcusto: mov.nomeCcusto ?? '-',
                    valorliquido: mov.valorliquido,
                    pedidomae: pedidoMae,
                });
            });
            setDados(dadosProcessados);

            setUltimaAtualizacao(getDataAtualMov());
        }
        catch (err) {
            console.log(err);
            setErro('Ocorreu um erro ao carregar os dados, tente novamente mais tarde.');
        }
        finally {
            setBtnAtualizaDisable(false);
            setCarregando(false);
        }
    }

    // console.log("data inicial ->", dataInicial);
    // console.log("data final ->", dataFinal);
    // console.log("data inicial formatada ->", formataData(dataInicial));

    //renderização da expansão da coluna
    const expandRow = {
        renderer: row => (
            <div>
                <p><strong>Pedido Mãe: </strong>{row.pedidomae}</p>
            </div>
        ),
        showExpandColumn: true,
        expandColumnPosition: 'right',
        expandByColumnOnly: true,
        onlyOneExpanding: true,
        nonExpandable: semPedidoMae,
        expandColumnRenderer: ({ expandable }) => {
            if (expandable) {
                return (
                    <Button variant="outline-primary" size="sm" data-placement="left" title="Pedido Mãe" >
                        <FontAwesomeIcon icon="sitemap" />
                    </Button>
                );
            }
            return (
                <></>
            );
        }
    };

    const CustomSearch = (props) => {
        let input;
        const handleChange = () => {
            props.onSearch(input.value);
        }

        return (
            <div className="col col-md-7">
                <input
                    className="form-control mt-4"
                    ref={n => input = n}
                    onChange={() => handleChange()}
                    placeholder="Pesquisar..."
                    type="text" />
            </div>
        );
    }

    return (
        <>
            <Navbar />
            <Container fluid className="container-card">
                <Card className="card-visao">
                    <Card.Body>
                        {erro &&
                            <div className="alert alert-danger" role="alert">
                                <FontAwesomeIcon icon="times" className="mr-2" />
                                {erro}
                            </div>}
                        <Row className="mt-3 mb-1">

                            <CardTitulo
                                titulo="Visão de Movimentos"
                                subtitulo={"EMPRESA: " + sessionStorage.getItem("RC_CODCOLIGADA") + " - " +
                                    sessionStorage.getItem("RC_NOMECOLIGADA")}
                            />

                            <Col className='text-right'>
                                <p className="text-muted mb-0">
                                    <small>Última Atualização: {ultimaAtualizacao ?? '-'}</small>
                                </p>
                                <Button variant="outline-primary" size="sm" onClick={() => getDados(sessionStorage.getItem('RC_STATUSVISAO'))}
                                    disabled={btnAtualizaDisable}>
                                    <FontAwesomeIcon icon="sync-alt" className="mr-2" />
                                    {btnAtualizaDisable ? "Atualizando..." : "Atualizar"}
                                </Button>
                            </Col>
                        </Row>

                        <ToolkitProvider
                            keyField="idlan"
                            data={dados ?? ''}
                            columns={colunas}
                            search>
                            {
                                parametros => (
                                    <>
                                        <form>
                                            <Row>
                                                <Col className="text-left mt-2">
                                                    <CustomSearch {...parametros.searchProps} />
                                                </Col>

                                                <Col md={"7"} className="align-right">
                                                    <Form className="mr-2">
                                                        <Form.Row>
                                                            <Form.Group>
                                                                <Form.Label>Status</Form.Label>
                                                                <Form.Control as="select" value={sessionStorage.getItem('RC_STATUSVISAO')}
                                                                    onChange={(e) => getDados(e.target.value)}>
                                                                    <option value="0">Aguardando Aprovação</option>
                                                                    <option value="1">Aprovados</option>
                                                                    <option value="2">Reprovados</option>
                                                                </Form.Control>
                                                            </Form.Group>

                                                            <Form.Group as={Col} controlId="dataInicial">
                                                                <Form.Label>Data Inicial</Form.Label>
                                                                <Form.Control type="date"
                                                                    value={dataInicial}
                                                                    disabled={carregando}
                                                                    onChange={handleChangeDataInicial}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} controlId="dataFinal">
                                                                <Form.Label>Data Final</Form.Label>
                                                                <Form.Control type="date"
                                                                    value={dataFinal}
                                                                    disabled={carregando}
                                                                    onChange={handleChangeDataFinal}
                                                                />
                                                            </Form.Group>

                                                            <div style={{ marginTop: '27px', marginLeft: '6px' }}>
                                                                <Button onClick={e => getDados(sessionStorage.getItem('RC_STATUSVISAO'))} type="button"
                                                                    variant="primary" disabled={carregando}>
                                                                    <FontAwesomeIcon icon="sync-alt" className="mr-2" />
                                                                    Carregar Dados
                                                                </Button>
                                                            </div>
                                                        </Form.Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </form>
                                        <BootstrapTable
                                            {...parametros.baseProps}
                                            noDataIndication={<Alert variant="secondary" className="text-center">Nenhum registro encontrado!</Alert>}
                                            hover={true}

                                            loading={carregando}
                                            bootstrap4={true}
                                            bordered={false}
                                            overlay={overlayFactory({ spinner: true })}
                                            wrapperClasses="table-responsive"
                                            pagination={paginationFactory()}
                                            sort={{
                                                dataField: ordenacao.field,
                                                order: ordenacao.order
                                            }} />

                                    </>
                                )
                            }
                        </ToolkitProvider>


                        {/* <ToolkitProvider
                            keyField="idmov"
                            data={dados ?? ''}
                            columns={colunas}
                            search>
                            {
                                parametros => (
                                    <>
                                        <Row>
                                            <Col md={3}>
                                                <Form >
                                                    <Form.Group>
                                                        <Form.Control as="select" value={sessionStorage.getItem('RC_STATUSVISAO')}
                                                            onChange={(e) => getDados(e.target.value)}>
                                                            <option value="0">Aguardando Aprovação</option>
                                                            <option value="1">Aprovados</option>
                                                            <option value="2">Reprovados</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form>
                                            </Col>
                                            <Col md={{ span: 5, offset: 4 }} className="text-right">
                                                <SearchBar placeholder="Pesquisar" {...parametros.searchProps} />
                                            </Col>
                                        </Row>

                                        <BootstrapTable
                                            {...parametros.baseProps}
                                            noDataIndication={<Alert variant="secondary" className="text-center">Nenhum registro encontrado!</Alert>}
                                            hover={true}
                                            loading={carregando}
                                            bootstrap4={true}
                                            bordered={false}
                                            overlay={overlayFactory({ spinner: true })}
                                            wrapperClasses="table-responsive"
                                            expandRow={expandRow}
                                            pagination={paginationFactory()}
                                        />
                                    </>
                                )
                            }
                        </ToolkitProvider> */}
                    </Card.Body>
                </Card>
            </Container>
            <Rodape />
        </>
    );
}