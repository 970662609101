import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

export default function Icone({ icon, customClass = '' }) {
    return (
        <FontAwesomeIcon
            icon={icon}
            className={customClass} />
    );
}