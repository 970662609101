import React from 'react';
import Icone from '../Icone';

export default function Notification(props) {
    return (
        <div>
            {props.sucesso ?
                <>
                    <Icone icon={'thumbs-up'} customClass='text-success mr-2' visible={props.mensagem === ''} />
                    <strong>Salvo com sucesso!</strong>
                </>
                :
                <>
                    <Icone icon={'times'} customClass='text-danger mr-2' />
                    <strong>{props.mensagem}</strong>
                </>
            }
        </div>
    );
}