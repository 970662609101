import React from 'react';
import { Link } from 'react-router-dom'

import { ButtonGroup, Button } from 'react-bootstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom';


library.add(fas);

export default function BtnVizualizacao(props) {
    const history = useHistory();
    return (
        <ButtonGroup>
            {
                props.permissao == 'T' ?
                    <Link to={`/detalheLancamento/${props.idlan}`}
                        className="btn btn-success btn-sm btn-grid mr-1" data-placement="right" title="Abrir lançamento">
                        <FontAwesomeIcon icon="link" />
                    </Link>
                    :
            <ButtonGroup>
                <Button disabled={true} className="btn btn-success btn-sm btn-grid mr-1" data-placement="right" title="Você não possui permissão para acessar o lançamento.">
                <FontAwesomeIcon icon="link" />
                </Button>
            </ButtonGroup>
            }

        </ButtonGroup>
    );
}