import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import DropDownUsuario from './components/dropDownUsuario';
import { Link, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import logo from '../../assets/img/logo-compacto.svg'
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

export default function NavBar() {
    let history = useHistory();
    
    function GoToMenu() {
        history.push('/app');
    }

    return (
        <Navbar bg="primary" variant="dark" fixed="top">
            <Navbar.Brand className='pt-0 pb-0'>
                <img src={logo} alt='' height={40} className='mr-2 d-lg-inline d-none' />
                <Link to="/app" className="navbar-brand pb-0 pt-0"><strong>PORTAL</strong> RC</Link>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    {sessionStorage.getItem('RC_APROVACAO_EXIBEMENU') === 'true' ?
                        <Button variant="primary" onClick={() => GoToMenu()} >
                            <FontAwesomeIcon icon="caret-square-left" className="mr-2" />
                        Voltar ao Menu
                    </Button>
                        :
                        <></>
                    }
                </Nav>
                <DropDownUsuario />
            </Navbar.Collapse>
        </Navbar>
    );
}