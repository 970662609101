import React, { Component } from 'react';
import { Button, Modal, Col, Badge, Form, Row } from 'react-bootstrap'
import api from '../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);


class ModalImpressao extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: '',
            dados: '',
            semRelatorio: '',
            carregando: '',
            mensagem: ''
        }
    }


    componentDidMount() {
        this.setState({ show: false, semRelatorio: false, tipoMensagem: "", carregando: false });
    }


    getRelatorio = async () => {

        let config = {
            headers: {
                'IDMOV': this.props.idmov,
            }
        }


        try {

            this.setState({ carregando: true, mensagem: "" });


            const response = await api.get('/NotaFiscalFornecedor', config);
            this.setState({ dados: response.data.arquivo });

            switch (response.data.status) {

                case "0":
                    this.setState({ mensagem: "Sem dados de relatório", semRelatorio: true, tipoMensagem: "primary", carregando: false });
                    break;

                case "1":
                    this.setState({ dados: response.data.arquivo, tipoMensagem: "", carregando: false });
                    this.handleShow();
                    break;

                default:
                    this.setState({ mensagem: "Erro ao gerar relatório!", tipoMensagem: "danger", carregando: false });
            }

        }
        catch (err) {
            this.setState({ mensagem: 'Erro ao carregar o relatório!', tipoMensagem: "danger", carregando: false })
        }

    }


    handleShow = () => this.setState({ show: true });
    handleClose = () => this.setState({ show: false });

    handleDownload = () => {

        let base64str = this.state.dados;

        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);

        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        var blob = new Blob([view], { type: 'application/pdf' });

        var fileURL = URL.createObjectURL(blob);

        var a = document.createElement('a');
        a.href = fileURL;
        a.target = "_blank";
        a.download = `danfe-${this.props.idmov}.pdf`;
        document.body.appendChild(a);
        a.click();

    }


    render() {
        return (
            this.props.visible === true ?
                <Col className="text-right">
                    <Button variant="primary" onClick={this.getRelatorio} disabled={this.props.disabled} data-placement="right" title="Imprimir DANF-e">
                        {this.state.carregando !== true ?
                            <>
                                <FontAwesomeIcon icon="print" className="mr-2" />
                                Imprimir DANF-e
                            </>
                            :
                            <>
                                <FontAwesomeIcon icon="sync-alt" className="mr-2" />
                                Carregando...
                            </>
                        }
                    </Button>

                    <Badge variant={this.state.tipoMensagem} className="ml-2">
                        {this.state.mensagem}
                    </Badge>{' '}

                    <Modal
                        show={this.state.show}
                        onHide={this.handleClose}
                        size="xl"
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Visualizar DANF-e</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={3}>
                                    <Form>
                                        <h6>Dados de entrada</h6>
                                        <hr className="mt-0" />

                                        <Form.Group md={3}>
                                            <Form.Label>Entrada Física</Form.Label>
                                            <Form.Control value={this.props.entradaFisica} readOnly />
                                        </Form.Group>

                                        <Form.Group md={3}>
                                            <Form.Label>Entrada Fiscal</Form.Label>
                                            <Form.Control value={this.props.entradaFiscal} readOnly />
                                        </Form.Group>

                                        <h6 className="mt-3">Aprovadores do Movimento</h6>
                                        <hr className="mt-0" />

                                        <Form.Group md={3}>
                                            <Form.Label>Aprovador 1</Form.Label>
                                            <Form.Control value={this.props.useraprovacao1} readOnly />
                                        </Form.Group>

                                        <Form.Group md={3}>
                                            <Form.Label>Aprovador 2</Form.Label>
                                            <Form.Control value={this.props.useraprovacao2} readOnly />
                                        </Form.Group>

                                        <Form.Group md={3}>
                                            <Form.Label>Aprovador 3</Form.Label>
                                            <Form.Control value={this.props.useraprovacao3} readOnly />
                                        </Form.Group>
                                    </Form>
                                </Col>

                                <Col>
                                    <iframe id="iframePdf" title={`medição-${this.props.idmov}`} src={`data:application/pdf;base64,${this.state.dados}`}
                                        style={{ width: '100%', height: '450px' }} />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>

                            <Button variant="light" onClick={this.handleDownload}>
                                <FontAwesomeIcon icon="file-download" className="mr-2" />
                                Fazer Download em PDF
                            </Button>

                            <Button variant="primary" onClick={this.handleClose}>Fechar</Button>

                        </Modal.Footer>
                    </Modal>
                </Col>
                :
                <></>
        );
    }
}

export default ModalImpressao;