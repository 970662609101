import React, { useEffect, useState } from 'react';
import { getData } from '../../util/getData';
import api from '../../services/apiMineracao';

import { formataDinheiro } from '../../util/formatacao'
import { Card, Row, Col } from 'react-bootstrap';

export default function CardTotais(props) {
    const [dados, setDados] = useState('');

    useEffect(() => {
        const config = {
            headers: {
                "DATAPROGRAMACAO": props.data ?? getData()
            }
        }

        api.get('/ListaValoresDia', config)
            .then(resp => setDados(resp.data))
            .catch(err => window.alert(err.toString()))
    }, [props.data])


    return (
        <Card className='p-2'>
            <Row>
                <Col>
                    <Card.Text> <strong>Qtde NF:</strong> {formataDinheiro(dados.QTDENOTASPRG)}</Card.Text>
                </Col>
                <Col>
                    <Card.Text> <strong>Programado T:</strong> {formataDinheiro(dados.TOTALPROGRAMADOENTREGA ?? 0)}</Card.Text>
                </Col>
                <Col>
                    <Card.Text><strong> Entregue T:</strong> {formataDinheiro(dados.QTDEENTREGUEPRG ?? 0)}</Card.Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card.Text><strong>% da programação:</strong> {formataDinheiro(dados.PERCQTDEPRG ?? 0)}%</Card.Text>
                </Col>
                <Col>
                    <Card.Text><strong>Qtde de NF Total:</strong> {formataDinheiro(dados.QTDENOTASTOTALDIA ?? 0)}</Card.Text>
                </Col>
                <Col>
                    <Card.Text><strong>Qtde T Entregue Total:</strong> {formataDinheiro(dados.QTDETOTALDIA ?? 0)}</Card.Text>
                </Col>
            </Row>
        </Card>
    );
}