import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import { Container, Card, Col, Row, Alert, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Navbar from '../../components/navbar';
import CardTotais from './components/cardTotais';
import CardTitulo from '../../components/cardTitulo';
import Rodape from '../../components/rodape';
import { getData, getDataMaisDias } from '../../util/getData';
import { formataDinheiro, formataData } from '../../util/formatacao';
import { useHistory } from 'react-router-dom';
import BadgeStatusMov from '../../components/badgeStatusMov';
import BtnVisaoLanc from './components/btnVisaoLanc';

import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import IconAprovadorCompra from '../../assets/img/iconAprovadorCompra.png';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);


export default function VisaoFinanceiro() {
    const [dados, setDados] = useState(['']);
    const [carregando, setCarregando] = useState(true);
    const [status, setStatus] = useState(sessionStorage.getItem("RCSTATUSFIN") ? sessionStorage.getItem("RCSTATUSFIN") : 'T');
    const [dataInicial, setDataInicial] = useState(sessionStorage.getItem("RCDATAINI") ? sessionStorage.getItem("RCDATAINI") : getData());
    const [dataFinal, setDataFinal] = useState(sessionStorage.getItem("RCDATAFIM") ? sessionStorage.getItem("RCDATAFIM") : getDataMaisDias(15));
    const [ordenacao, setOrdenacao] = useState({ field: localStorage.getItem('col') ?? 'id', order: localStorage.getItem('ord') ?? 'asc' });
    const [erro, setErro] = useState('');
    const history = useHistory();


    const colunas = [
        {
            dataField: 'botoes',
            text: '',
            headerAlign: 'left',
            align: 'left',
            sort: false,
        },
        {
            dataField: 'reavaliar',
            text: 'Status',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => <BadgeStatusMov status={value !== undefined ? value : '-'} />,
            sort: true,
            onSort: (field, order) => handleSort(field, order),
        },
        {
            dataField: 'idlan',
            text: 'Ref.',
            headerAlign: 'left',
            align: 'left',
            sort: true,
            onSort: (field, order) => handleSort(field, order)
        },
        {
            dataField: 'numerodocumento',
            text: 'Num. Documento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
            onSort: (field, order) => handleSort(field, order)
        },
        {
            dataField: 'dataemissao',
            text: 'Emissão',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
            onSort: (field, order) => handleSort(field, order)
        },
        {
            dataField: 'dataprevbaixa',
            text: 'Prev. Baixa',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
            onSort: (field, order) => handleSort(field, order)
        },
        {
            dataField: 'datavencimento',
            text: 'Vencimento',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
            onSort: (field, order) => handleSort(field, order)
        },
        {
            dataField: 'valor',
            text: 'Valor',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value),
            sort: true,
            onSort: (field, order) => handleSort(field, order)
        },
        {
            dataField: 'cnpj',
            text: 'CNPJ',
            headerAlign: 'left',
            align: 'left',
            sort: true,
            onSort: (field, order) => handleSort(field, order)
        },
        {
            dataField: 'empresaFc',
            text: 'Fluxo de Caixa',
            headerAlign: 'left',
            align: 'left',
            sort: true,
            onSort: (field, order) => handleSort(field, order)
        },

        {
            dataField: 'fornecedor',
            text: 'Fornecedor',
            headerAlign: 'left',
            align: 'left',
            sort: true,
            onSort: (field, order) => handleSort(field, order)
        },
        {
            dataField: 'aprovadorcompra',
            text: 'Aprov. Compra',
            headerAlign: 'left',
            align: 'left',
            sort: false,
            formatter: (value) => <TooltipAprovadorCompra value={value} />,
        },
        {
            dataField: 'planoFinacneiro',
            text: 'Plano Financeiro',
            headerAlign: 'left',
            align: 'left',
            sort: true,
            onSort: (field, order) => handleSort(field, order)
        },
        {
            dataField: 'tipoDocto',
            text: 'Tipo Documento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
            onSort: (field, order) => handleSort(field, order)
        },
    ]


    useEffect(() => {
        if (setCarregando)
        {
            sessionStorage.getItem('RC_CODCOLIGADA') === 'A' ?
                history.push('/SelecionaEmpresa/0') : GetDados();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const TooltipAprovadorCompra = (aprovador = '-') => (
        <OverlayTrigger overlay={<Tooltip><strong>Aprovador de Compra: <br /> </strong> {aprovador.value}</Tooltip>}>
            <span className="d-inline-block">
                <img height='20' src={IconAprovadorCompra} style={{ pointerEvents: 'none' }} alt='' />
            </span>
        </OverlayTrigger>
    )


    async function GetDados() {
        setCarregando(true)

        let config = {
            headers: {
                "CODUSUARIO": sessionStorage.getItem('RC_USR'),
                "DATAINICIAL": formataData(dataInicial),
                "DATAFINAL": formataData(dataFinal),
                "STATUS": status,
            }
        }

        try
        {
            const response = await api.get('/AprovacaoLancamento', config);
            const respTratada = response.data.map((fin) => {
                return ({
                    ...fin,
                    botoes: <BtnVisaoLanc idlan={fin.idlan} status={fin.reavaliar} />,
                });
            });

            setDados(respTratada);
            sessionStorage.setItem("RCSTATUSFIN", status);

            if (dataInicial !== undefined && dataFinal !== undefined)
            {
                sessionStorage.setItem("RCDATAINI", dataInicial);
                sessionStorage.setItem("RCDATAFIM", dataFinal);
            }
        }
        catch (err)
        {
            setErro("Erro API => " + err);
        }
        finally
        {
            setCarregando(false);
        }
    }

    const handleSort = (campo, ordem) => {
        localStorage.setItem("col", campo);
        localStorage.setItem("ord", ordem)
        setOrdenacao({ field: campo, order: ordem });
    }


    const CustomSearch = (props) => {
        let input;
        const handleChange = () => {
            props.onSearch(input.value);
        }

        return (
            <div className="col col-md-7">
                <input
                    className="form-control mt-4"
                    ref={n => input = n}
                    onChange={() => handleChange()}
                    placeholder="Pesquisar..."
                    type="text" />
            </div>
        );
    }


    return (
        <>
            <Navbar />
            <Container fluid className="container-card">
                <Card className="card-visao">
                    <Card.Body>
                        {erro &&
                            <div className="alert alert-danger" role="alert">
                                <FontAwesomeIcon icon="times" className="mr-2" />
                                {erro}
                            </div>}
                        <Row className="mt-3 mb-1">
                            <Col className="mt-3 mb-1">
                                <CardTitulo
                                    titulo="Visão de Lançamentos Financeiros"
                                    subtitulo={"EMPRESA: " + sessionStorage.getItem("RC_CODCOLIGADA") + " - " +
                                        sessionStorage.getItem("RC_NOMECOLIGADA")} />
                            </Col>
                            <Col>

                                <CardTotais
                                    dataInicial={dataInicial}
                                    dataFinal={dataFinal}
                                    carregando={carregando} />
                            </Col>
                        </Row>

                        <ToolkitProvider
                            keyField="idlan"
                            data={dados ?? ''}
                            columns={colunas}
                            search>
                            {
                                parametros => (
                                    <>
                                        <Row>
                                            <Col className="text-left mt-2">
                                                <CustomSearch {...parametros.searchProps} />
                                            </Col>

                                            <Col md={"7"} className="align-right">
                                                <Form className="mr-2">
                                                    <Form.Row>
                                                        <Form.Group as={Col} md={3} controlId="formGridStatus">
                                                            <Form.Label>Status</Form.Label>
                                                            <Form.Control as="select" value={status} disabled={carregando}
                                                                onChange={e => setStatus(e.target.value)}>
                                                                <option value="T">Aguardando Análise</option>
                                                                <option value="G">Todos</option>
                                                                <option value="A">Em Aprovação</option>
                                                                <option value="R">Reavaliar</option>
                                                                <option value="W">Reavaliado</option>
                                                                <option value="F">Aprovados</option>
                                                            </Form.Control>
                                                        </Form.Group>

                                                        <Form.Group as={Col} controlId="dataInicial">
                                                            <Form.Label>Data Inicial</Form.Label>
                                                            <Form.Control type="date" value={dataInicial} disabled={carregando}
                                                                onChange={e => setDataInicial(e.target.value)} />
                                                        </Form.Group>

                                                        <Form.Group as={Col} controlId="dataFinal">
                                                            <Form.Label>Data Final</Form.Label>
                                                            <Form.Control type="date" value={dataFinal} disabled={carregando}
                                                                onChange={e => setDataFinal(e.target.value)} />
                                                        </Form.Group>

                                                        <div style={{ marginTop: '27px', marginLeft: '6px' }}>
                                                            <Button onClick={e => GetDados()} type="button"
                                                                variant="primary" disabled={carregando}>
                                                                <FontAwesomeIcon icon="sync-alt" className="mr-2" />
                                                                Carregar Dados
                                                            </Button>
                                                        </div>
                                                    </Form.Row>
                                                </Form>
                                            </Col>
                                        </Row>

                                        <BootstrapTable
                                            {...parametros.baseProps}
                                            noDataIndication={<Alert variant="secondary" className="text-center">Nenhum registro encontrado!</Alert>}
                                            hover={true}

                                            loading={carregando}
                                            bootstrap4={true}
                                            bordered={false}
                                            overlay={overlayFactory({ spinner: true })}
                                            wrapperClasses="table-responsive"
                                            pagination={paginationFactory()}
                                            sort={{
                                                dataField: ordenacao.field,
                                                order: ordenacao.order
                                            }} />

                                    </>
                                )
                            }
                        </ToolkitProvider>
                    </Card.Body>
                </Card>
            </Container>
            <Rodape />
        </>
    )
}