import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import useKeyPress from '../../util/useKeyPress';

import { Modal, Button, Alert } from 'react-bootstrap';
import Icone from '../Icone';


export default function ModalAprovMultipla(props) {
    const [show, setShow] = useState(false);
    const [disabledAprov, setDisableAprov] = useState(false);
    const [mensagem, setMensagem] = useState()
    const [erro, setErro] = useState('');
    const history = useHistory();

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setErro('');
        setShow(false);
        setDisableAprov(false);
    };


    //Se pressionado a tecla A, abre o modal de aprovação
    const keyShow1 = useKeyPress('a');
    const keyShow2 = useKeyPress('A');
    const keyShow3 = useKeyPress('Alt');
    useEffect(() => {
        if (((keyShow1 || keyShow2) && keyShow3) && !show && !props.visao) {
            handleShow();
        }
        // eslint-disable-next-line
    }, [keyShow1, keyShow2])


    //Se pressionado a tecla ENTER com o modal aberto
    //faz a aprovação do lançamento
    const keyAprova = useKeyPress('Enter')
    useEffect(() => {
        if (keyAprova && show && !erro) {
            AprovaLancamento();
        }
        // eslint-disable-next-line
    }, [keyAprova])


    useEffect(() => {
        setMensagem("Deseja realmente aprovar o(s) lançamento(s) selecionado(s) ? ")

        //se for lançamento com outros lançamentos vinculados
        if (show && !props.visao && props.lancamentos) {

            let selec = props.lancamentos.selectionContext.selected;
            if (selec.indexOf(props.idlanAtual) < 0) {
                setErro(`É obrigatória a seleção do lançamento atual. (Ref: ${props.idlanAtual} )`);
                setDisableAprov(true);
            }
            else {
                //zera as mensages e desbloqueia o botão quando sai do modal
                if (erro) {
                    setErro("");
                    setDisableAprov(false);
                }
            }
        }
        // eslint-disable-next-line
    }, [show])


    function AprovaLancamento() {
        setDisableAprov(true);
        setMensagem('Aprovando, por gentileza aguarde...')

        let selec = '';
        if (props.lancamentos) {
            selec = props.lancamentos.selectionContext.selected
        }
        else {
            selec = [props.idlanAtual]
        }

        let body = {
            "LANCAMENTO": [{
                "CODCOLIGADA": sessionStorage.getItem("RC_CODCOLIGADA"),
                "IDLAN": "[" + selec.toString() + "]",
                "CODUSUARIO": sessionStorage.getItem("RC_USR")
            }]
        };

        api.post('/AprovarLancamento', body)
            .then((response) => {

                if (response.data.CODIGO === '1' && props.visao === false) {
                    history.goBack();
                }
                else if (response.data.CODIGO === '1' && props.visao === true) {
                    window.location.reload();
                }
                else {
                    setErro("ERRO: " + response.data.DESCRICAO.toString());
                }
            })
            .catch((err) => {
                setErro(err.toString());
            })
            .finally(() => {
                setDisableAprov(false);
            })
    }


    return (
        <>
            <Button variant="success" onClick={handleShow} disabled={props.disabled}>
                <Icone icon="thumbs-up" />
                {props.visao === false ? ' Aprovar' : ''}
            </Button>


            <Modal show={show} onHide={handleClose} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>Aprovação de Lançamentos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {erro ?
                        <Alert variant="danger">
                            <Icone icon="times" />
                            &nbsp; {erro}
                        </Alert>
                        :
                        mensagem
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Cancelar
                    </Button>

                    <Button variant="success" onClick={() => AprovaLancamento()} disabled={disabledAprov}>
                        <Icone icon="thumbs-up" className="mr-2" />
                        &nbsp; Aprovar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
