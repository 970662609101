import React, { useState } from 'react';
import api from '../../../../services/api';

import { Accordion, Card, Spinner, Alert } from 'react-bootstrap';
import CardAprovador from './components/cardAprovador';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);


export default function CardAprovadores(props) {
    const [dados, setDados] = useState([]);
    const [show, setShow] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [erro, setErro] = useState(false);


    async function GetDados() {
        setCarregando(true);

        if (!show) {
            setShow(true);

            let config = {
                headers: {
                    'IDMOV': props.idmov,
                    'CODUSUARIO': sessionStorage.getItem('RC_USR'),
                    'IDAPROVACAO': props.idaprovacao,
                }
            }

            try {
                const response = await api.get('/AprovacoesMovimento', config);
                setDados(response.data);
            }
            catch (err) {
                setErro(true);
                console.error(err);
            }
            finally {
                setCarregando(false);
            }
        }
        else {
            setShow(false);
        }
    }

    return (
        <Accordion defaultActiveKey="0">
            <Card>
                <Accordion.Toggle as={Card.Header} onClick={GetDados} eventKey="1">
                    <FontAwesomeIcon icon="sort-amount-down-alt" className="mr-2" />
                    Aprovadores deste item.
            </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    {carregando ?
                        <div className="text-center">
                            <Spinner className="mb-3 mt-3" animation="border" variant="primary" />
                        </div>
                        :
                        erro === false ?
                            <div className="container-fluid mt-3">
                                {dados !== undefined ? dados.map((aprovador) => (
                                    <div key={aprovador.seq}>

                                        <CardAprovador
                                            status={aprovador.status}
                                            seq={aprovador.seq}
                                            usuarioOrigem={aprovador.usuarioorigem}
                                            usuarioAlternativo={aprovador.usuarioalter}
                                            usuarioAprovacao={aprovador.usuarioaprovacao}
                                            dataAprovacao={aprovador.dataaprovacao} />

                                    </div>

                                )) : <Alert variant="secondary">Sem Dados de aprovadores</Alert>}
                            </div>

                            : <Alert variant="danger">Erro ao carregar os dados. Tente novamente mais tarde.</Alert>
                    }
                </Accordion.Collapse>
            </Card>
        </Accordion >
    );
}