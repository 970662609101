import React from 'react';
import { Badge } from 'react-bootstrap'

const StatusColigada = (props) => {

    const perfil = JSON.parse(sessionStorage.RC_PERFIL);
    const permissaoFin = (perfil.FINANCEIRO === 'T')
    const permissaoMov = (perfil.APROVACAO === 'T')

    return (
        <>
            {(props.fin === 1 && permissaoFin) && <Badge variant="success" className="mr-1" data-toggle="tooltip" title="Novos lançamentos Financeiros">Financeiro</Badge>}
            {(props.mov === 1 && permissaoMov) && <Badge variant="primary" data-toggle="tooltip" title="Novos Movimentos">Movimentos</Badge>}
        </>
    );
}

export default StatusColigada;