import React from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { formataDinheiro } from '../../util/formatacao'

export default function FormDadosConfirmacao(props) {
    return (
        <Form>
            <Form.Row>
                <FormGroup className="col-md-2">
                    <Form.Label htmlFor="inputNumMov">Num. Movimento</Form.Label>
                    <Form.Control readOnly id="inputNumMov" value={props.nummov} />
                </FormGroup>

                <FormGroup className="col-md-6">
                    <Form.Label htmlFor="inputNomeFantasia">Nome Fantasia</Form.Label>
                    <Form.Control readOnly id="inputNomeFantasia" value={props.nomefantasia} />
                </FormGroup>

                <FormGroup className="col">
                    <Form.Label htmlFor="inputVlrLiquido">Valor Líquido</Form.Label>
                    <Form.Control readOnly id="inputVlrLiquido" value={formataDinheiro(props.valorliquido ?? 0)} />
                </FormGroup>
            </Form.Row>
        </Form>
    );
}