import React from 'react';
import { Col } from 'react-bootstrap';

export default function CardTitulo(props) {
    return (
        <Col sm >
            <h3 className="mb-0">{props.titulo}</h3>
            <p className="text-muted"><small>{props.subtitulo}</small></p>
        </Col>
    );
}
