import React, { useState, useEffect } from 'react'
import api from '../../services/apiMineracao';
import { FormatNumber, FormatDate2, FormatMoney } from '../../util/utilLibrary';
import moment from 'moment';

import { Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ButtonContent from '../../components/buttonContent';
import Notification from '../../components/Notification';
import Icone from '../../components/Icone';
import iconSaldo from '../../assets/img/icon_saldo20.png';


export default function FormProgramacao(props) {
    const [materiais, setMateriais] = useState('');
    const [carregando, setCarregando] = useState(false)
    const [materialSelecionado, setMaterialSelecionado] = useState('');
    const [habilitaCampos, setHabilitaCampos] = useState(false);
    const [programacao, setProgramacao] = useState('');
    const [mensagemSalvar, setMensagemSalvar] = useState('');
    const [retornoSalva, setRetornoSalva] = useState(false);
    const [salvando, setSalvando] = useState(false)
    const [dataProgramacao, setDataProgramacao] = useState();
    const [dadosSaldo, setDadosSaldo] = useState({ "LIMITECREDITO": "0,00", "TOTALEMABERTO": "0,00", "SALDO": "0,00" });
    const [carregandoSaldo, setCarregandoSaldo] = useState(false);
    const [erroSaldo, setErroSaldo] = useState();


    useEffect(() => {

        if (props.status === 'Novo' && props.item.idorcamento !== undefined)
        {
            GetListaPedidos(props.item.idorcamento);
            GetSaldo(props.item.codcfo, props.item.codcolcfo);
            setHabilitaCampos(true);
        }
        else if (props.status === 'Edicao' && props.show)
        {
            GetProgramacao(props.item.idprogramacao)
            GetSaldo(props.item.codcfo, props.item.codcolcfo);
            setHabilitaCampos(props.item.statusnfe === 'T' && props.item.editavel === 1);
        }

        // eslint-disable-next-line
    }, [props.item])



    function GetSaldo(codcfo, codcolcfo) {
        setCarregandoSaldo(true);
        const config = {
            headers: {
                "CODCOLCFO": codcolcfo,
                "CODCFO": codcfo
            }
        }

        api.get('/LimiteCreditoCliente', config)
            .then(resp => setDadosSaldo(resp.data))
            .catch(err => setErroSaldo(err.toString()))
            .finally(() => setCarregandoSaldo(false));
    }


    // Carrega a listagem de pedidos liberados para o usuário logado
    function GetListaPedidos(idorcamento) {
        const config = {
            headers: {
                "IDORCAMENTO": idorcamento
            }
        }

        api.get('/ListaPedidosItens', config)
            .then(resp => {
                setMateriais(resp.data)
            })
            .catch(err => window.alert(err.toString()))
    }



    function GetProgramacao(idprogramacao) {
        setCarregando(true)
        const config = {
            headers: {
                "IDPROGRAMACAO": idprogramacao
            }
        }

        api.get('/EditaProgramacao', config)
            .then(resp => {
                resp = resp.data[0];

                setProgramacao(resp);
                
                
                const config = {
                    headers: {
                        "IDORCAMENTO": resp.idorcamento
                    }
                }
                
                api.get('/ListaPedidosItens', config)
                    .then(ped => {
                        let val = ped.data.filter(x => x.idprd === resp.idprd);
                        setMateriais(val);
                        setMaterialSelecionado(val[0]);
                    })
                    .catch(err => window.alert(err.toString()))
            })
            .catch(err => window.alert(err.toString()))
            .finally(() => setCarregando(false))
    }



    function SalvaProgramacao(e) {
        e.preventDefault();

        retornoSalva && setRetornoSalva('');

        let prog = e.target;

        if (moment().isAfter(prog.dataprogramacao.value, 'day'))
        {
            setMensagemSalvar({ message: 'A data não pode ser inferior a data de hoje', sucesso: false });
        }
        else if (prog.material.value === '')
        {
            setMensagemSalvar({ message: 'É obrigatória a seleção de um material.', sucesso: false });
        }
        else if (prog.quantprogram.value <= 0)
        {
            setMensagemSalvar({ message: 'É obrigatório definir a quanitdade de material.', sucesso: false });
        }
        else
        {
            setSalvando(true);

            const body = [{
                'CODCOLIGADA': sessionStorage.getItem('RC_CODCOLIGADA'),
                'IDPROGRAMACAO': prog.refPedido.value ?? 0,
                'IDORCAMENTO': programacao.idorcamento ?? props.item.idorcamento,
                'NSEQITMORCAMENTO': 0,
                'IDPRD': parseInt(prog.material.value),
                'QUANTIDADET': parseFloat(prog.quantprogram.value),
                'DATAPROGRAMACAO': prog.dataprogramacao.value,
                'PRIORIDADE': 5, //parseInt(prog.prioridade.value),
                'OBSERVACAO': prog.observacao.value,
                'REFERENCIA': prog.referenciaentrega.value,
                'USUARIO': sessionStorage.getItem('RC_USRMINERACAO'),
                'CONTATO': prog.contato.value
            }]

            api.post('/PutProgramacao', body)
                .then(resp => {
                    if (resp.data.CODIGO === '1')
                    {
                        let notificacao = document.getElementById("showNotificacaoSalva");
                        notificacao.click();

                        props.handleClose();
                    }
                    else
                    {
                        setMensagemSalvar({ message: resp.data.DESCRICAO, sucesso: false });
                        setSalvando(false);
                    }
                })
                .catch(err => {
                    window.alert(err.toString());
                    setSalvando(false);
                });
        }
    }


    function ExibeSaldo() {
        return (
            <>
                <OverlayTrigger
                    key='tooltip'
                    placement='bottom'
                    className='text-left'
                    overlay={
                        <Tooltip id='saldo' style={{ 'whiteSpace': 'pre-line' }}>
                            <div className='text-left'>
                                {carregandoSaldo ?

                                    <p className='mt-3'>Carregando...</p>

                                    :
                                    <>
                                        {erroSaldo ?
                                            <p>{erroSaldo}</p>
                                            :
                                            <p className='titulo-item-tooltip mt-3'>
                                                <span>Limite de Crédito:</span> R$ {dadosSaldo.LIMITECREDITO} <br />
                                                <span>Total em aberto:</span> R$ {dadosSaldo.TOTALEMABERTO} <br />
                                                <span>Saldo:</span> R$ {dadosSaldo.SALDO} <br />
                                            </p>
                                        }
                                    </>
                                }
                            </div>
                        </Tooltip>
                    }
                >
                    <p style={{ marginTop: '26px', marginBottom: '0px' }}><img src={iconSaldo} alt="" className='mr-2' height='20' />Ver Saldo do cliente</p>

                </OverlayTrigger>
            </>
        );
    }


    return (
        <>
            {!carregando ?
                <form className='form mt-4' onSubmit={e => SalvaProgramacao(e)}>
                    <div className='form-row'>
                        <div className='form-group col-md-2'>
                            <TextBoxComponent name="refPedido" placeholder="Ref. Pedido" value={programacao.idprogramacao ?? 0}
                                floatLabelType='Always' readOnly />
                        </div>
                        <div className='form-group col-md-3'>
                            <TextBoxComponent name="numeroauxiliar" placeholder="Núm. Auxiliar" value={props.item.numeroauxiliar} floatLabelType='Always'
                                readOnly />
                        </div>
                        <div className='form-group col'>
                            <TextBoxComponent name="nomefantasia" placeholder="Nome do Cliente" value={props.item.nomefantasia ?? programacao.cliente}
                                floatLabelType='Always' readOnly />
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-group col'>
                            <TextBoxComponent name='endereco' placeholder="Endereço" floatLabelType='Always'
                                value={props.item.endereco ?? programacao.enderecoentrega} readOnly />
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-group col-md'>
                            <DropDownListComponent name='material' placeholder="Material*" dataSource={materiais} value={programacao.idprd}
                                fields={{ text: 'nomefantasia', value: 'idprd' }} floatLabelType='Always' enabled={habilitaCampos}
                                onChange={e => setMaterialSelecionado(e.target.itemData)} />
                        </div>
                        <div className='form-group col-md-2'>
                            <TextBoxComponent name="quantt" placeholder="Quant. T" floatLabelType='Always'
                                readOnly value={FormatNumber(materialSelecionado.quantidadet ?? programacao.qtdeorc)} format='N3' />
                        </div>
                        <div className='form-group col-md-2'>
                            <TextBoxComponent name="quantentt" placeholder="Quant. Ent. T" floatLabelType='Always'
                                readOnly value={FormatNumber(materialSelecionado.quantidadeentreguet ?? programacao.qtdeentregueprd)} />
                        </div>

                        <div className='form-group col-md-2 text-center'>
                            <ExibeSaldo />
                        </div>
                    </div>

                    <div className='form-row'>
                        <div className='form-group col-md'>
                            <NumericTextBoxComponent name="quantprogram" locale='pt-BR' format='n2' min={0} decimals={2} enabled={habilitaCampos}
                                placeholder="Quant. Ton. Programada" floatLabelType='Always' value={programacao.quantidadet ?? 0} />
                        </div>
                        <div className='form-group col-md'>
                            <TextBoxComponent name="status" placeholder="Status" floatLabelType='Always' readOnly value={programacao.descStatus} />
                        </div>
                        <div className='form-group col-md'>
                            <TextBoxComponent name="feitoweb" placeholder="Feito pela Web?" floatLabelType='Always' readOnly value={programacao.mobile === 0 ? 'Não' : 'Sim'} />
                        </div>
                        <div className='form-group col-md'>
                            <TextBoxComponent name="dataprogramacao" value={FormatDate2(dataProgramacao ?? programacao.dataprogramacao)}
                                format="DD/MM/yyyy" placeholder="Data" floatLabelType='Always' enabled={habilitaCampos}
                                type="date" onChange={e => setDataProgramacao(e.target.value)} />
                        </div>

                    </div>

                    <div className='form-row'>

                        <div className="form-group col-md-2">
                            <TextBoxComponent name="valorUnitario" type='text' placeholder='Valor Unitário' floatLabelType='Always'
                                value={FormatMoney(materialSelecionado.valorundprdt)} readonly />
                        </div>
                        <div className="form-group col-md-2">
                            <TextBoxComponent name="valorFrete" type='text' placeholder='Valor Frete' floatLabelType='Always'
                                value={FormatMoney(materialSelecionado.valorfrete)} readonly />
                        </div>
                        <div className="form-group col-md-2">
                            <TextBoxComponent name="valorTotal" type='text' placeholder='Valor total' floatLabelType='Always'
                                value={FormatMoney(materialSelecionado.precot)} readonly />
                        </div>

                        <div className='form-group col'>
                            <TextBoxComponent name="contato" type='text' placeholder='Contato' floatLabelType='Always'
                                value={programacao.contato} enabled={habilitaCampos} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md">
                            <TextBoxComponent multiline={true} name="observacao" placeholder='Observação' floatLabelType='Always'
                                value={programacao.observacao} enabled={habilitaCampos} required />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md">
                            <TextBoxComponent multiline={true} name="referenciaentrega" placeholder="Ref. da Entrega" floatLabelType='Always'
                                value={programacao.referenciaentrega} enabled={habilitaCampos} />
                        </div>
                    </div>


                    <div className='row'>
                        <div className='col md-2'>
                            {props.status === 'Novo' ?
                                <Button variant='outline-secondary' onClick={() => props.handleVoltaTab()} disabled={mensagemSalvar.sucesso}>
                                    <Icone icon='arrow-left' customClass='mr-2' />
                                    Voltar
                                </Button> : <></>}
                        </div>

                        <div className='col-md text-right pt-1'>
                            {(mensagemSalvar !== '') ?
                                <Notification mensagem={mensagemSalvar.message} sucesso={mensagemSalvar.sucesso} />
                                : <></>}
                        </div>

                        <div className='col-md-2 text-right'>
                            <Button variant='light' className='mr-2' onClick={() => props.handleClose()}>Cancelar</Button>

                            <Button variant='primary' type='submit' disabled={!habilitaCampos || salvando}>
                                <ButtonContent texto='Salvar' textoCarregando='Salvando' carregando={salvando} />
                            </Button>
                        </div>
                    </div>
                </form >

                : <div style={{ textAlign: 'center', margin: '30vh 0' }}>
                    <Spinner animation="border" variant="primary" />
                </div>
            }
        </>
    )
}