import React, { useState } from 'react';
import api from '../../../../services/api';

import { Accordion, Card, Spinner, Alert } from 'react-bootstrap';
import CardVencimento from './components/cardVencimento';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);


export default function CardVencimentos(props) {
    const [show, setShow] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [erro, setErro] = useState(false);
    const [vencimentos, setVencimentos] = useState();

    async function GetDados() {
        setCarregando(true);

        if (!show) {
            setShow(true);

            let config = {
                headers: {
                    'IDMOV': props.idmov,
                    'CODUSUARIO': sessionStorage.getItem('RC_USR'),
                }
            }

            try {
                const response = await api.get('/DetalheMovimentoVencimentos', config)
                setVencimentos(response.data);


            }
            catch (err) {
                setErro(true);
                console.error(err);
            }
            finally {
                setCarregando(false);
            }
        }
        else {
            setShow(false);
        }
    }
    let tamanho = vencimentos ? vencimentos.length : 0;
    
    return (
        <Accordion defaultActiveKey="0">
            <Card>
                <Accordion.Toggle as={Card.Header} onClick={GetDados} eventKey="1">
                    <FontAwesomeIcon icon="sort-amount-down-alt" className="mr-2" />
                    Consultar Vencimentos.
            </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    {carregando ?
                        <div className="text-center">
                            <Spinner className="mb-3 mt-3" animation="border" variant="primary" />
                        </div>
                        :
                        erro === false ?
                            <div className="container-fluid mt-3">
                                {tamanho > 0 ? vencimentos.map((vencimento) => (
                                    <div key={vencimento.idlan}>

                                        <CardVencimento
                                            codColigada={vencimento.codcoligada}
                                            dataPrevBaixa={vencimento.dataprevbaixa}
                                            dataVencimento={vencimento.datavencimento}
                                            idLan={vencimento.idlan}
                                            idMov={vencimento.idmov}
                                            numDoc={vencimento.numerodocumento}
                                            statusLan={vencimento.statusLan}
                                            valorOriginal={vencimento.valororiginal}                                            
                                        />

                                    </div>

                                )) : <Alert variant="secondary">Sem Dados de Vencimentos</Alert>}
                            </div>

                            : <Alert variant="danger">Erro ao carregar os dados. Tente novamente mais tarde.</Alert>
                    }
                </Accordion.Collapse>
            </Card>
        </Accordion >
    );
}