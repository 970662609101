import React, { useState } from 'react';
import api from '../../services/apiMineracao';

import { ColumnDirective, ColumnsDirective, GridComponent, Sort, Inject, Page } from '@syncfusion/ej2-react-grids';
import { Modal, Button, Tabs, Tab, Col, Form, InputGroup, FormControl, Spinner, Alert } from 'react-bootstrap';
import Icone from '../../components/Icone';
import Programacao from '../../components/programacao/formProgramacao';


export default function Edicao(props) {
    const [show, setShow] = useState(false);
    const [operacao, setOperacao] = useState('');
    const [pedidos, setPedidos] = useState('');
    const [selecionado, setSelecionado] = useState('');
    const [Grid, setGrid] = useState('');
    const [carregando, setCarregando] = useState(false);
    const [erro, setErro] = useState('')

    const [tabAtiva, setTabAtiva] = useState('selecaoPedido')

    const handleShow = (op) => {
        setOperacao(op);
        (op === 'Novo') && ListaPedidos();
        
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        props.atualizaVisao();
        erro && setErro('');
        setSelecionado('');
        setTabAtiva('selecaoPedido');
        
    }

    const handleAvancaTab = () => setTabAtiva('programacao');
    const handleVoltaTab = () => setTabAtiva('selecaoPedido');
    

    const handleDoubleClick = () => {
        const btn = document.getElementById('btnAvancarProg');
        btn.click();
    }


    function search(e) {
        e.preventDefault();
        
        let serchText = e.target.searchbox.value;
        (Grid) && Grid.search(serchText);
    }
    
    
    function ListaPedidos() {
        setCarregando(true);
        
        const config = {
            headers: {
               CODUSUARIO: sessionStorage.getItem("RC_USRMINERACAO"),
            }
        }

        api.get('/ListaPedidos', config)
            .then(resp => {
                setPedidos(resp.data)
            })
            .catch(err => setErro(err.toString()))
            .finally(() => setCarregando(false))
    }



    return (
        <>
            <Button variant='light' onClick={() => handleShow('Novo')}>
                <Icone icon='plus' customClass='mr-2 text-success' />
                Novo
            </Button>

            <Button id='btnEditarProgramacao' variant='light' onClick={() => handleShow('Edicao')} disabled={!props.item}>
                <Icone icon='edit' customClass='mr-2 text-primary' />
                Editar
            </Button>


            <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Icone icon='calendar-check' customClass='mr-2 text-primary' />
                        {(operacao === 'Novo') ? 'Nova programação' : 'Programação N° ' + props.item.numeroauxiliar}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {operacao === 'Novo' ?
                        <Tabs activeKey={tabAtiva}>
                            <Tab eventKey='selecaoPedido' title='Seleção do Pedido'>
                                <Form onSubmit={e => search(e)} className='mt-3'>
                                    <Form.Group as={Col} md>
                                        <InputGroup>
                                            <FormControl
                                                placeholder="Pesquisar"
                                                name='searchbox'
                                            />
                                            <InputGroup.Append>
                                                <Button type='submit' variant="light" style={{ border: '1px solid #ced4da' }}>
                                                    <Icone icon="search" />
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                </Form>

                                {erro && <Alert variant='danger'>{erro}</Alert>}

                                <GridComponent ref={grid => setGrid(grid)} dataSource={pedidos} allowSorting={true} allowPaging={true}
                                    rowSelected={e => setSelecionado(e.data)} recordDoubleClick={e => handleDoubleClick(e)} >
                                    <ColumnsDirective>
                                        <ColumnDirective field='numeroauxiliar' headerText="Núm. Auxiliar" width='130' />
                                        <ColumnDirective field='idorcamento' headerText="Id Orçamento" width='135' />
                                        <ColumnDirective field='nomefantasia' headerText="Nome" width='500' />
                                        <ColumnDirective field='endereco' headerText="Endereço" width='500' />
                                    </ColumnsDirective>
                                    <Inject services={[Sort, Page]} />
                                </GridComponent>

                                <div className='text-right mt-3'>
                                    <Button variant='light' className='mr-2' onClick={() => handleClose()}>Cancelar</Button>

                                    <Button id='btnAvancarProg' variant='outline-primary' onClick={() => handleAvancaTab()} disabled={selecionado === ''}>
                                        Avançar
                                        <Icone icon='arrow-right' customClass='ml-2' />
                                    </Button>
                                </div>
                            </Tab>
                            <Tab eventKey='programacao' title='Programação'>
                                {carregando ?
                                    <div style={{ textAlign: 'center', margin: '30vh 0' }}>
                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                    : <Programacao item={selecionado}   status='Novo'
                                        handleVoltaTab={() => handleVoltaTab()}  handleClose={() => handleClose()}/>}
                            </Tab>
                        </Tabs>
                        :
                        <Programacao item={props.item} show={show} status='Edicao' handleClose={() => handleClose()} />
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}