import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import { formataDinheiro } from '../../util/formatacao';

import Rodape from '../../components/rodape';
import CardDeckAprovadores from './components/cardDeckAprovadores';
import CardDeckVencimentos from './components/cardDeckVencimentos';
import FormDetalheMovimento from './components/formDetalheMovimento';
import Navbar from '../../components/navbar';
import Loading from '../../components/loading';
import CardDetalhes from '../../components/cardDetalhes';
import { MensagemErro } from '../../components/alerts';
import BtnDetalheMovRodape from './components/btnDetalheMovRodape'
import LancVinculados from './components/lancVinculados';
import './style.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import DataGrid from '../../components/datagrid';
import TabelaClassificacao from '../detalheItensMovimento/components/tabelaClassificacao';

library.add(fas);


export default function DetalheMovimento(props) {
    const [dados, setDados] = useState('');
    const [itens, setItens] = useState([]);
    const [carregando, setCarregando] = useState(false);
    const [erro, setErro] = useState('');

    //Define as colunas da tabela de itens
    const colunas = [
        {
            dataField: 'botoes',
            text: '',
            headerAlign: 'left',
            align: 'left',
            sort: false,
        },
        {
            dataField: 'nomefantasia',
            text: 'Descrição do Item',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'descccusto',
            text: 'Centro de Custo',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'idobjoficina',
            text: 'Obj. Manutenção',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'quantidade',
            text: 'Quant.',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => value.toFixed(2).replace('.', ','),
            sort: true,
        },
        {
            dataField: 'codund',
            text: 'Und.',
            headerAlign: 'center',
            align: 'center',
            sort: true,
        },
        {
            dataField: 'precounitario',
            text: 'Valor Unitário',
            headerAlign: 'right',
            align: 'right',
            visibility: false,
            formatter: (value) => formataDinheiro(value),
            sort: true,
        },
        {
            dataField: 'valortotalitem',
            text: 'Valor Total',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value),
            sort: true,
        },
    ]


    useEffect(() => {
        async function getDados() {
            setCarregando(true);

            let config = {
                headers: {
                    'IDMOV': props.match.params.idmov,
                    'CODUSUARIO': sessionStorage.getItem('RC_USR'),
                }
            };

            try {
                const response = await api.get('/DetalheMovimento', config)
                setDados(response.data);

                let listaItens = response.data.itemmov !== undefined ? response.data.itemmov.map((item, index) => {
                    return ({
                        id: index,
                        botoes: <Link to={`/DetalheItem/${item.idmov}/${response.data.idaprovacao}/${item.nseqitmmov}/${props.match.params.statusMov}`}
                            className="btn btn-primary btn-sm btn-grid mr-1" data-placement="right" title="Detalhes do Item">
                            <FontAwesomeIcon icon="list-alt" />
                        </Link>,
                        nomefantasia: item.nomefantasia ?? '-',
                        descccusto: item.descccusto ?? '-',
                        idobjoficina: item.idobjoficina ?? '-',
                        quantidade: item.quantidade ?? 0,
                        codund: item.codund ?? '',
                        precounitario: item.precounitario ?? 0,
                        valortotalitem: item.valortotalitem ?? 0,
                    });
                }) : '';

                setItens(listaItens);

            }
            catch (err) {
                setErro(`> ${err}`);
            }
            finally {
                setCarregando(false);
            }
        }
        getDados();
    }, [props])


    return (
        <div>
            <Navbar />

            <CardDetalhes titulo="Detalhes do Movimento" subtitulo="Verifique todos os dados abaixo."
                btnImprimeVisible={true} disabled={carregando} idmov={props.match.params.idmov}>
                <MensagemErro mensagem={erro} />
                {carregando ?
                    <Loading />
                    :
                    <>
                        <FormDetalheMovimento
                            numeromov={dados.numeromov ?? '-'}
                            nomefantasia={dados.nomefantasia ?? '-'}
                            dataemissao={dados.dataemissao ?? '-'}
                            tipomov={dados.codtmv + " - " + dados.descTmv ?? '-'}
                            nomecomprador={dados.nomeVendedor ?? '-'}
                            idmov={dados.idmov ?? '-'}
                            condicaopagamento={dados.descCpg ?? '-'}
                            quantidadeitens={dados.itemmov === undefined ? '' : dados.itemmov.length}
                            valorbruto={dados.valorbruto}
                            valorliquido={dados.valorliquido}
                            valorfrete={dados.valorfrete}
                            observacao={dados.observacao ?? ''}
                            historico={dados.historico ?? ''}
                        />

                        <CardDeckVencimentos
                            idmov={dados.idmov}
                        />

                        <br />

                        <CardDeckAprovadores
                            idmov={dados.idmov}
                            idaprovacao={props.match.params.idaprovacao}
                        />

                        <br />

                        <TabelaClassificacao
                            tblHeader1={props.match.params.tblheader1}
                            tblHeader2={props.match.params.tblheader2}
                            tblHeader3={props.match.params.tblheader3}
                            tblHeader4={props.match.params.tblheader4}
                            tblHeader5={props.match.params.tblheader5}
                            tblDesc1={props.match.params.tbldesc1}
                            tblDesc2={props.match.params.tbldesc2}
                            tblDesc3={props.match.params.tbldesc3}
                            tblDesc4={props.match.params.tbldesc4}
                            tblDesc5={props.match.params.tbldesc5}
                        />

                        <br />

                        <DataGrid
                            identificador="id"
                            colunas={colunas}
                            dados={itens ?? ''}
                            paginacao={true}
                        />

                        <LancVinculados 
                            idmov={props.match.params.idmov}
                        />

                        <div className="text-right">
                            <hr />

                            <BtnDetalheMovRodape
                                status={props.match.params.statusMov}
                                idmov={dados.idmov}
                                numeromov={dados.numeromov}
                                nomefantasia={dados.nomefantasia}
                                valorliquido={dados.valorliquido}
                                codtmv={dados.codtmv}
                                usuariomestre={dados.usuariomestre}
                                dependencia={dados.dependencia}
                                idaprovacao={dados.idaprovacao}
                                sequencial={dados.sequencial}
                                codusuario={dados.codusuario}
                                nomeVendedor={dados.nomeVendedor} />
                        </div>
                    </>
                }
            </CardDetalhes>
            <Rodape />
        </div>
    );
}
