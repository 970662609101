import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { formataData, formataDinheiro } from '../../../../util/formatacao';
import api from '../../../../services/api';
import { Link } from 'react-router-dom';

import ModalAprovMultipla from '../../../../components/ModalAprovMultipla';
import ModalReavaliaLanc from '../../../../components/modalReavaliaLanc';
import useKeyPress from '../../../../util/useKeyPress';

import { Alert, Button, Badge, ButtonGroup } from 'react-bootstrap';
import StatusBx from '../statusBx';
import Icone from '../../../../components/Icone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BtnVizualizacao from '../btnVizualizacao';

export default function LancVinculados(props) {
    const [node, setNode] = useState('');
    const history = useHistory();
    const [dados, setDados] = useState();
    const [erro, setErro] = useState();
    const [carregando, setCarregando] = useState(true);

    useEffect(() => {
        async function getDados() {
            let config = {
                headers: {
                    'IDMOV': props.idmov,
                }
            };

            try {
                const response = await api.get('/ListaLancamentoMovimento', config);
                let dadosProcessados = response.data.map((lan) => {
                    return ({
                        botoes: <BtnVizualizacao idlan={lan.idlan} permissao={perfil.FINANCEIRO} />
                        ,
                        idlan: lan.idlan,
                        numerodocumento: lan.numerodocumento,
                        status: lan.status,
                        statuslan: lan.statuslan,
                        dataemissao: lan.dataemissao,
                        dataprevbaixa: lan.dataprevbaixa,
                        datavencimento: lan.datavencimento,
                        valor: lan.valor,
                        empresaFc: lan.empresaFc,
                        planoFinacneiro: lan.planoFinacneiro,
                        tipoDocto: lan.tipoDocto,
                    });
                });
                setDados(dadosProcessados);

            }
            catch (err) {
                setErro(`> ${err}`);
            } finally {
                setCarregando(false);
            }
        }
        getDados();
    }, [props])

    const selected = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: [props.idlan],
        nonSelectable: getFechados()
    }

    const perfil = JSON.parse(sessionStorage.RC_PERFIL);


    const colunas = [
        {
            dataField: 'botoes',
            text: 'Ações',
            headerAlign: 'left',
            align: 'left',
            sort: false,

        },
        {
            dataField: 'idlan',
            text: 'Ref.',
            headerAlign: 'left',
            align: 'left',
            sort: true,

        },
        {
            dataField: 'numerodocumento',
            text: 'Num. Documento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'status',
            text: 'Status',
            headerAlign: 'center',
            align: 'center',
            sort: true,
            formatter: (value) => (
                <Badge variant={value === 'A' ? 'primary' : 'success'} > {value}</ Badge>
            )
        },
        {
            dataField: 'statuslan',
            text: 'Status Bx',
            headerAlign: 'center',
            align: 'center',
            sort: true,
            formatter: (value) => (
                <StatusBx status={value} />
            )
        },
        {
            dataField: 'dataemissao',
            text: 'Emissão',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
        },
        {
            dataField: 'dataprevbaixa',
            text: 'Prev. Baixa',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
        },
        {
            dataField: 'datavencimento',
            text: 'Vencimento',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => formataData(value),
            sort: true,
        },
        {
            dataField: 'valor',
            text: 'Valor',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value ?? 0),
            sort: true,
        },
        {
            dataField: 'empresaFc',
            text: 'Fluxo de Caixa',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'planoFinacneiro',
            text: 'Plano Financeiro',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'tipoDocto',
            text: 'Tipo Documento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
    ]

    const key1 = useKeyPress("v")
    const key2 = useKeyPress("V")
    const key3 = useKeyPress("Alt")
    useEffect(() => {
        if ((key1 || key2) && key3) {
            history.goBack();
        }
        // eslint-disable-next-line
    }, [key1, key2, key3])


    //pega os lançamentos que não podem ser aprovados
    //novamente e retorna em um array para travar a grid
    function getFechados() {
        let valores = [];
        if (dados) {
            valores = dados.map((item) => {
                if (item.status !== 'A' || item.statuslan !== 0) {
                    return item.idlan
                }
            })
        }
        return valores;
        // eslint-disable-next-line
    }


    return (
        <>
            <h5 className="mt-4 mb-3" >Lançamentos Vinculados</h5>
            {dados ?
                <>
                    <BootstrapTable
                        keyField="idlan"
                        data={dados}
                        loading={carregando}
                        columns={colunas}
                        noDataIndication={<Alert variant="secondary" className="text-center">Nenhum registro encontrado!</Alert>}
                        hover={true}
                        bootstrap4={true}
                        ref={n => setNode(n)}
                        selectRow={selected}
                        pagination={paginationFactory()}
                        bordered={false}
                        wrapperClasses="table-responsive"
                    />


                    <div className="row mb-2">
                        <div className="col-4">
                            <div className="container">
                                <h6>Status</h6>

                                <Badge variant="success" className="mr-1">F</Badge>
                                <small>Já Aprovado</small>

                                <Badge variant="primary" className="ml-3 mr-1">A</Badge>
                                <small>Aberto</small>
                            </div>
                        </div>

                        <div className="col">
                            <h6>Status Bx</h6>

                            <Badge variant="primary" className="mr-1">0</Badge>
                            <small>Em aberto</small>

                            <Badge variant="success" className="ml-3 mr-1">1</Badge>
                            <small>Baixado</small>

                            <Badge variant="danger" className="ml-3 mr-1">2</Badge>
                            <small>Cancelado</small>

                            <Badge variant="warning" className="ml-3 mr-1">3</Badge>
                            <small>Baixado com Acordo</small>

                            <Badge variant="dark" className="ml-3 mr-1">4</Badge>
                            <small>Baixado Parcialmente</small>
                        </div>
                    </div>
                    <hr />
                </>
                : <></>
            }
        </>
    );
}