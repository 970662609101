import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { formataData } from '../../../../../../util/formatacao'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

export default function CardAprovador(props) {
    const [icone, setIcone] = useState('clock');
    const [tipo, setTipo] = useState('');
    const [titulo, setTitulo] = useState('');

    useEffect(() => {
        switch (props.status) {
            case 'F':
                setTitulo('Aprovado!')
                setIcone('thumbs-up');
                setTipo('success');
                break;

            case 'R':
                setTitulo('Reprovado!')
                setIcone('times');
                setTipo('danger');
                break;

            default:
                setTitulo('Aguardando Avaliação!')
                setIcone('clock');
                setTipo('primary');
                break;
        }
    }, [props]);

    return (
        <Alert variant={tipo}>
            <Alert.Heading>
                <FontAwesomeIcon icon={icone} className="mr-2" />
                {titulo}
            </Alert.Heading>
            <strong>Sequencia: </strong>{props.seq}<br />
            <strong>Aprovador Original: </strong>{props.usuarioOrigem} <br />
            <strong>Aprovador Alternativo: </strong>{props.usuarioAlternativo} <br />
            {props.status === 'F' || props.status === 'R' ?
                <>
                    <hr />
                    <strong>{props.status === 'F' ? 'Aprovado' : 'Reprovado'} por: </strong> {props.dataAprovacao !== null ? `${props.usuarioAprovacao} - ${formataData(props.dataAprovacao)}` : '-'}
                </>
                : <></>}
        </Alert>
    );
}