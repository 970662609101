import React, { useState, useEffect } from 'react';
import BootstrapDataGrid from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import api from '../../services/api';

import LayoutDefault from '../../components/layoutDefault';
import CardVisao from '../../components/cardVisao';
import Rodape from '../../components/rodape';
import { Alert, Form, InputGroup, Button } from 'react-bootstrap';


export default function VisaoSolicitacaoCompras() {
    const [dataProjetos, setDataProjetos] = useState([]);
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [projetoAtivo, setProjetoAtivo] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [carregando, setCarregando] = useState(false);
    const [erro, setErro] = useState('');

    const colunas = [
        {
            dataField: 'botoes',
            text: '',
            headerAlign: 'left',
            align: 'left',
            sort: false,
        },
        {
            dataField: 'codigoprd',
            text: 'Código',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'nomefantasia',
            text: 'Descrição',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'quantidade',
            text: 'Quantidade',
            headerAlign: 'right',
            align: 'right',
            sort: true,
            formatter: value => (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)),
        },
        {
            dataField: 'qtde. em aprovação',
            text: 'Qtde em Aprovação',
            headerAlign: 'right',
            align: 'right',
            sort: true,
            formatter: value => (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)),
        },
        {
            dataField: 'qtde. solicitada',
            text: 'Qtde Solicitada',
            headerAlign: 'right',
            align: 'right',
            sort: true,
            formatter: value => (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)),
        },
        {
            dataField: 'qtde. pedido',
            text: 'Qtde Pedido',
            headerAlign: 'right',
            align: 'right',
            sort: true,
            formatter: value => (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)),
        },
        {
            dataField: 'qtde. notas',
            text: 'Qtde. Notas',
            headerAlign: 'right',
            align: 'right',
            sort: true,
            formatter: value => (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)),
        },
        {
            dataField: 'qtde. anp/amp',
            text: 'Qtde. ANP/AMP',
            headerAlign: 'right',
            align: 'right',
            sort: true,
            formatter: value => (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)),
        },
        {
            dataField: 'qtde. np/mp',
            text: 'Qtde NP/MP',
            headerAlign: 'right',
            align: 'right',
            sort: true,
            formatter: value => (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)),
        },
        {
            dataField: 'qtde. baixa estoque',
            text: 'Qtde Baixa Estoque',
            headerAlign: 'right',
            align: 'right',
            sort: true,
            formatter: value => (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)),
        },
        {
            dataField: 'qtde. devolução',
            text: 'Qtde. Devolução',
            headerAlign: 'right',
            align: 'right',
            sort: true,
            formatter: value => (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)),
        },
        {
            dataField: 'qtde. saldo',
            text: 'Qtde Saldo',
            headerAlign: 'right',
            align: 'right',
            sort: true,
            formatter: value => (new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)),
        },
    ]

    const paginationOptions = {
        sizePerPageList: [{
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '200', value: 200
        }]
    };


    useEffect(() => {
        GetData();
    }, []);


    async function GetData() {
        setDataProjetos([]);
        
        setCarregando(true);
        setMensagem('Selecione um projeto para começar!')
        try {
            const response = await api.get('/ProjetosdoUsuario');
            setDataProjetos(response.data);
        }
        catch (err) {
            setErro('> ' + err)
        }
        finally {
            setCarregando(false);
        }
    }


    async function handleClickSelecionar(e) {
        e.preventDefault();
        setCarregando(true);
        setMensagem('');

        const config = {
            "headers": {
                "idprj": projetoAtivo,
            }
        }

        try {
            const response = await api.get('/SolicitacaoCompras', config);
            setSolicitacoes(response.data);
        }
        catch (err) {
            setErro(`erro ${err}`)
        }
        finally {
            setCarregando(false)
        }
    }


    function FormProjeto() {
        return (
            <Form onSubmit={e => handleClickSelecionar(e)}>
                <Form.Group controlId="selectProjetosUsuario" className="mt-2" onChange={(e) => setProjetoAtivo(e.target.value)}>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroupPrepend">Projeto</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select">
                            <option value="00" defaultValue>Selecione...</option>
                            {dataProjetos.map(item => (
                                <option value={item.idprj}>{item.codprj + ' - ' + item.descricao}</option>
                            ))}
                        </Form.Control>
                        <Button type="submit" variant="outline-primary">Selecionar</Button>
                    </InputGroup>
                </Form.Group>
            </Form>
        );
    }


    return (
        <LayoutDefault fluid>
            <CardVisao titulo="Visão de Solicitação de Compras" option={(FormProjeto())} className="mb-5" >
                <div className="mt-3">
                    {erro !== '' ? <Alert variant="danger">{erro}</Alert> : <></>}
                    {mensagem ?
                        <Alert variant="primary" className="text-center">{mensagem}</Alert>
                        :

                        <BootstrapDataGrid
                            keyField="codigoprd"
                            noDataIndication={<Alert variant="secondary" className="text-center">Nenhum registro encontrado!</Alert>}
                            hover={true}
                            loading={carregando}
                            overlay={overlayFactory({ spinner: true })}
                            bootstrap4={true}
                            bordered={false}
                            data={solicitacoes ?? ''}
                            columns={colunas}
                            pagination={paginationFactory(paginationOptions)}
                            wrapperClasses="table-responsive" />
                    }
                </div>
            </CardVisao>
            <Rodape />
        </LayoutDefault>
    );
}