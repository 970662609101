export const RC_COD = "0";

export const isAuthenticated = () => sessionStorage.getItem(RC_COD) !== null;

export const getAuth = () => sessionStorage.getItem(RC_COD);

//recebe o codigo do usuario e salva no storage
export const login = authCode => {
  sessionStorage.setItem(RC_COD, authCode);
};

export const logout = () => {
  sessionStorage.removeItem(RC_COD);
};

