import React from 'react';
import { Spinner } from 'react-bootstrap';
import './style.css';


export default function LayoutLoading() {
    return (
        <div className="loading-container">
            <Spinner animation="border" variant="primary" role="status" />
            <p className="mt-2">Carregando</p>
        </div>
    );
}