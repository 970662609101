import React from 'react';
import DataGrid from '../../../../components/datagrid';
import { formataData, formataDinheiro } from '../../../../util/formatacao';

export default function ItensMovimento(props) {

    const columns = [
        {
            dataField: 'nseqitmmov',
            text: 'Seq.',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'nomefantasia',
            text: 'Nome',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        },
        {
            dataField: 'dataentrega',
            text: 'Data Entrega',
            headerAlign: 'center',
            align: 'center',
            formatter: (value) => value === null ? '-' : formataData(value),
            sort: true,
        },
        {
            dataField: 'quantidade',
            text: 'Quant.',
            headerAlign: 'right',
            align: 'right',
            sort: true,
        },
        {
            dataField: 'codund',
            text: 'Unidade',
            headerAlign: 'center',
            align: 'center',
            sort: true,
        },
        {
            dataField: 'precounitario',
            text: 'Vlr. Unitário',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value),
            sort: true,
        },
        {
            dataField: 'valortotalitem',
            text: 'Valor Total',
            headerAlign: 'right',
            align: 'right',
            formatter: (value) => formataDinheiro(value),
            sort: true,
        },
        {
            dataField: 'equipamento',
            text: 'Equipamento',
            headerAlign: 'left',
            align: 'left',
            sort: true,
        }
    ];

    return (
        <>
            <h5 className="mt-4 mb-3" >Itens do Movimento</h5>

            <DataGrid
                identificador="codccusto"
                colunas={columns}
                dados={props.dados ?? ''}
                paginacao={true}
            />
        </>
    );
}