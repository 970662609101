import React from 'react';
import { Form, FormGroup, Col } from 'react-bootstrap';
import { formataDinheiro, formataData } from '../../util/formatacao';


export default function FormDetalheItem(props) {
    return (
        <Form>
            <Form.Row>
                <FormGroup as={Col} md={2}>
                    <Form.Label htmlFor="inputCod">Cód. Produto</Form.Label>
                    <Form.Control readOnly id="inputCod" value={props.codprd} />
                </FormGroup>

                <FormGroup as={Col}>
                    <Form.Label htmlFor="inputDescricao">Descrição</Form.Label>
                    <Form.Control readOnly id="inputDescricao" value={props.descricao} />
                </FormGroup>

                <FormGroup as={Col} md={1}>
                    <Form.Label htmlFor="inputUnd">Unidade</Form.Label>
                    <Form.Control readOnly id="inputUnd" value={props.codund} />
                </FormGroup>

                <FormGroup as={Col} md={2}>
                    <Form.Label htmlFor="inputDataEntrega">Data da Entrega</Form.Label>
                    <Form.Control readOnly id="inputDataEntrega" value={formataData(props.dataentrega) ?? ''} />
                </FormGroup>
            </Form.Row>

            <Form.Row>
                <FormGroup as={Col}>
                    <Form.Label htmlFor="inputCentroCusto">Centro de Custo</Form.Label>
                    <Form.Control readOnly id="inputCentroCusto" value={props.centrocusto} />
                </FormGroup>

                <FormGroup as={Col} md={2}>
                    <Form.Label htmlFor="inputVlrOrcamento">Valor do Orçamento</Form.Label>
                    <Form.Control readOnly id="inputVlrOrcamento" value={formataDinheiro(props.valorsolicitacao ?? 0)} />
                </FormGroup>

                <FormGroup as={Col} md={1}>
                    <Form.Label htmlFor="inputQuantidade">Quantidade</Form.Label>
                    <Form.Control readOnly id="inputQuantidade" value={props.quantidade} />
                </FormGroup>

                <FormGroup as={Col} md={2}>
                    <Form.Label htmlFor="inputVlrUnitario">Valor Unitário</Form.Label>
                    <Form.Control readOnly id="inputVlrUnitario" value={formataDinheiro(props.valorUnitario ?? 0)} />
                </FormGroup>

                <FormGroup as={Col} md={2}>
                    <Form.Label htmlFor="inputVlrTotal">Valor Total</Form.Label>
                    <Form.Control readOnly id="inputVlrTotal" value={formataDinheiro(props.valorTotal ?? 0)} />
                </FormGroup>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label htmlFor="inputHistorico">Histórico</Form.Label>
                    <Form.Control as="textarea" rows="5" readOnly id="inputHistorico" value={props.historico ?? ''} />
                </Form.Group>
            </Form.Row>
        </Form>
    );
}