import React from 'react';
import { Alert } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

const AlertErros = (props) => (
    <Alert variant='danger'>
        <FontAwesomeIcon className="mr-2" icon="times" />
        {props.mensagem}
    </Alert>
)
export default AlertErros